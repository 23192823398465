import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ActivityModel from 'app/shared/model/custom/custom-activity.model';
import ShiftModel from 'app/shared/model/custom/custom-shift.model';
import { IEligibleEquipments } from 'app/shared/model/custom/eligible-equipments.model';
import { IEligibleResources } from 'app/shared/model/custom/eligible-resources.model';
import { CalendarPlanningMode, PlanningView, ShiftDirection } from 'app/shared/types/types';
import { getBgColorById } from 'app/shared/util/color-utils';
import { dateToString } from 'app/shared/util/date-utils';
import axios from 'axios';

export type PlanningStateType = {
  loading: boolean;
  shifts: ShiftModel[];
  shift: ShiftModel[];
  eligibleResources: IEligibleResources;
  eligibleEquipments: IEligibleEquipments;
  loadingResources: boolean;
  shiftRefreshed: number;
  calendarPlanningMode?: CalendarPlanningMode;
  activeView: PlanningView;
};
export const initialState: PlanningStateType = {
  loading: false,
  shifts: [],
  shift: [],
  eligibleResources: { remainingResources: [], shiftResources: [], shiftPreferredResources: [] },
  eligibleEquipments: { equipmentInCurrentDepartment: [], equipmentInOtherDepartments: [] },
  loadingResources: true,
  shiftRefreshed: 0,
  calendarPlanningMode: 'SHIFT_VIEW',
  activeView: 'planning',
};

const API_BASE_URL = '/api';
let cancelToken;

export type ShiftsByStartDateAndEndDateParams = {
  startDate: Date;
  endDate: Date;
};

export type printShiftParams = {
  shiftId: number;
  format: string;
};

export type ShiftDemandePayload = {
  shiftDemandId: number;
  payload: any;
};

export type EligibleResourcesParams = {
  shiftId: number;
  organigramId: number;
};

export type EligibleEquipmentsParams = {
  shiftId: number;
  departmentId: number;
};

export type CranesAutoDemandParams = {
  cranes: number;
  shiftId: number;
  departmentId?: number;
};

export type RefActivityTypeAutoDemandParams = {
  shiftId: number;
  refActivityTypeId: number;
  departmentId?: number;
};

type ShiftDetailsParams = {
  shiftId: number;
  shiftDirection?: ShiftDirection;
};

type AutoPlanShiftParams = {
  shiftId: number;
  otherTeams?: boolean;
};

type DuplicateShiftParams = {
  originShiftId: number;
  destinationShiftId: number;
  demands: boolean;
  resources: boolean;
  equipment: boolean;
};
type ClearPlansParams = {
  shiftId: number;
  demands: boolean;
  resources: boolean;
  equipment: boolean;
};

export const getShiftsByStartDateAndEndDate = createAsyncThunk(
  `shift/shiftsByStartDateAndEndDate`,
  async ({ startDate, endDate }: ShiftsByStartDateAndEndDateParams) => {
    cancelOldRequest();
    const requestUrl = `${API_BASE_URL}/shifts/start-time-between?startDate=${dateToString(startDate)}&endDate=${dateToString(endDate)}`;
    return axios.get<ShiftModel[]>(requestUrl, { cancelToken: cancelToken.token });
  },
);

export const getShiftDetails = createAsyncThunk(`shift/shiftDetails`, async ({ shiftId, shiftDirection }: ShiftDetailsParams) => {
  cancelOldRequest();
  const requestUrl = `${API_BASE_URL}/shifts/shift-details/${shiftId}?shiftDirection=${shiftDirection ?? ''}`;
  return axios.get<ShiftModel[]>(requestUrl, { cancelToken: cancelToken.token });
});

export const updateShiftDemand = createAsyncThunk(`api/updateShiftDemand`, async ({ shiftDemandId, payload }: ShiftDemandePayload) => {
  const requestUrl = `${API_BASE_URL}/shift-demands/${shiftDemandId}`;
  cancelOldRequest();
  return axios.put<any>(requestUrl, payload, { cancelToken: cancelToken.token });
});

export const updateShiftDemands = createAsyncThunk(`api/updateShiftDemands`, async (data: any[]) => {
  const requestUrl = `${API_BASE_URL}/shift-demands`;
  cancelOldRequest();
  return axios.put<any>(requestUrl, data, { cancelToken: cancelToken.token });
});

export const submitShift = createAsyncThunk('shift/submitShift', async (shiftId: number) => {
  const requestUrl = `${API_BASE_URL}/shifts/submit/${shiftId}`;
  cancelOldRequest();
  return axios.put<any>(requestUrl, { cancelToken: cancelToken.token });
});

export const autoPlanShift = createAsyncThunk('shift/autoPlanShift', async ({ shiftId, otherTeams }: AutoPlanShiftParams) => {
  const requestUrl = `${API_BASE_URL}/shifts/autoPlanning/${shiftId}?otherTeams=${otherTeams}`;
  cancelOldRequest();
  return axios.post<any>(requestUrl, { cancelToken: cancelToken.token });
});

export const updateShiftsActivities = createAction(
  'shifts/updateShiftsActivities',
  (activities: ActivityModel[], departmentId: number) => ({
    payload: { activities, departmentId },
  }),
);

export const approveShift = createAsyncThunk('shift/approveShift', async (shiftId: number) => {
  const requestUrl = `${API_BASE_URL}/shifts/approve/${shiftId}`;
  cancelOldRequest();
  return axios.put<any>(requestUrl, { cancelToken: cancelToken.token });
});

export const splitShift = createAsyncThunk('shift/splitShift', async (shiftId: number, { rejectWithValue }) => {
  const requestUrl = `${API_BASE_URL}/shifts/split/${shiftId}`;
  try {
    const response = await axios.post<any>(requestUrl);

    if (response.data) {
      return response.data;
    } else {
      return rejectWithValue('No data returned');
    }
  } catch (error) {
    // Handle the error, returning it in a user-friendly format
    if (error.response && error.response.data && error.response.data.detail) {
      // Return the detail message from the error response
      return rejectWithValue(error.response.data.detail);
    } else {
      return rejectWithValue('Unexpected error occurred');
    }
  }
});

export const printShift = createAsyncThunk<Blob, printShiftParams>('shift/printShift', async ({ shiftId, format }: printShiftParams) => {
  const requestUrl = `${API_BASE_URL}/report/shift?shiftIds=${shiftId}&format=${format}`;
  cancelOldRequest();
  const response = await axios.get(requestUrl, { responseType: 'blob' });
  return response.data;
});

export const getEligibleResources = createAsyncThunk(
  `resource/eligibleResources`,
  async ({ shiftId, organigramId }: EligibleResourcesParams) => {
    cancelOldRequest();
    const requestUrl = `${API_BASE_URL}/resource/${shiftId}/eligible-resources/${organigramId}`;
    return axios.get<IEligibleResources>(requestUrl, { cancelToken: cancelToken.token });
  },
);

export const getEligibleEquipments = createAsyncThunk(
  `resource/eligibleEquipments`,
  async ({ shiftId, departmentId }: EligibleEquipmentsParams) => {
    cancelOldRequest();
    const requestUrl = `${API_BASE_URL}/equipment/${shiftId}/eligible-equipment/${departmentId}`;
    return axios.get<IEligibleEquipments>(requestUrl, { cancelToken: cancelToken.token });
  },
);

export const setShiftAutoDemand = createAsyncThunk(
  'shifts/{shiftId}/auto-demand/number-of-cranes',
  async ({ shiftId, cranes, departmentId }: CranesAutoDemandParams) => {
    const requestUrl = `${API_BASE_URL}/shifts/${shiftId}/auto-demand/number-of-cranes`;
    return axios.post<void>(requestUrl, null, { params: { cranes, departmentId } });
  },
);

export const setActivityTypeShiftAutoDemand = createAsyncThunk(
  'shifts/{shiftId}/auto-demand/activity-type',
  async ({ shiftId, refActivityTypeId, departmentId }: RefActivityTypeAutoDemandParams) => {
    const requestUrl = `${API_BASE_URL}/shifts/${shiftId}/auto-demand/activity-type`;
    return axios.post<void>(requestUrl, null, { params: { refActivityTypeId, departmentId } });
  },
);

export const getShiftEquipmentPlans = createAsyncThunk('shifts/shiftEquipmentPlans', async (shiftId: number) => {
  const requestUrl = `${API_BASE_URL}/equipment-plans/shift/${shiftId}`;
  return axios.get<ShiftModel[]>(requestUrl);
});

export const duplicateShift = createAsyncThunk(
  'shifts/{originShiftId}/duplicate/{destinationShiftId}',
  async ({ originShiftId, destinationShiftId, demands, resources, equipment }: DuplicateShiftParams) => {
    const requestUrl = `${API_BASE_URL}/shifts/${originShiftId}/duplicate/${destinationShiftId}`;
    return axios.post(requestUrl, null, { params: { demands, resources, equipment } });
  },
);

export const clearPlans = createAsyncThunk(
  'shifts/clear-plans/{shiftId}',
  async ({ shiftId, demands, resources, equipment }: ClearPlansParams) => {
    const requestUrl = `${API_BASE_URL}/shifts/clear-plans/${shiftId}`;
    return axios.delete(requestUrl, { params: { demands, resources, equipment } });
  },
);
export const refreshShift = createAction('shifts/refresh');

export const setCalendarPlanningMode = createAction<CalendarPlanningMode>('planning/setCalendarPlanningMode');

export const PlanningSlice = createSlice({
  name: 'planning',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setActiveView(state, action) {
      state.activeView = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getShiftsByStartDateAndEndDate.rejected, state => {
        state.loading = false;
        state.shifts = [];
      })
      .addCase(getShiftsByStartDateAndEndDate.fulfilled, (state, action) => {
        state.loading = false;
        state.shifts = action.payload.data;
      })
      .addCase(getShiftDetails.rejected, state => {
        state.loading = false;
        state.shifts = [];
      })
      .addCase(getShiftDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.shifts = action.payload.data;
      })
      .addCase(updateShiftsActivities, (state, action) => {
        const { activities, departmentId } = action.payload;

        const newShifts = state.shifts;
        for (let i = 0; i < newShifts.length; i++) {
          for (let e = 0; e < newShifts[i].facilities?.length; e++) {
            for (let x = 0; x < newShifts[i].facilities[e]?.departments.length; x++) {
              if (newShifts[i].facilities[e].departments[x].id === departmentId) {
                for (let y = 0; y < newShifts[i].facilities[e].departments[x].activities.length; y++) {
                  for (let z = 0; z < activities.length; z++) {
                    if (activities[z].id === newShifts[i].facilities[e].departments[x].activities[y].id) {
                      // Update activities based on current state
                      newShifts[i].facilities[e].departments[x].activities[y].color = activities[z].color;
                      newShifts[i].facilities[e].departments[x].activities[y].position = activities[z].position;
                    }
                  }
                }
              }
            }
          }
        }

        state.shifts = newShifts;
      })
      .addCase(refreshShift, (state, action) => {
        ++state.shiftRefreshed;
      })
      .addCase(updateShiftDemand.fulfilled, (state, action) => {
        const { arg } = action.meta;
        state.shifts = state.shifts.map(shift => {
          if (shift.id === arg.payload.shift.id) {
            let departmnets = shift?.facilities?.flatMap(facility => facility.departments) || [];
            const updatedDepartments = (departmnets ?? []).map(department => {
              if (department.id === arg.payload.organigram.refDepartment.id) {
                const updatedPositions = department.positions.map(position => {
                  if (position.id === arg.payload.organigram.refPosition.id && position.shiftDemand) {
                    const { shiftDemand } = position;
                    const updatedShiftDemand = { ...shiftDemand, headCount: arg.payload.headCount };
                    return { ...position, shiftDemand: updatedShiftDemand };
                  }
                  return position;
                });
                return { ...department, positions: updatedPositions };
              }
              return department;
            });
            return { ...shift, departments: updatedDepartments };
          }
          return shift;
        });
      })
      .addCase(getEligibleResources.pending, (state, action) => {
        state.loading = true;
        state.eligibleResources = { remainingResources: [], shiftResources: [], shiftPreferredResources: [] };
      })
      .addCase(getEligibleResources.fulfilled, (state, action) => {
        state.loading = false;
        state.eligibleResources = action.payload.data;
      })
      .addCase(getEligibleEquipments.pending, (state, action) => {
        state.loading = true;
        state.eligibleEquipments = { equipmentInCurrentDepartment: [], equipmentInOtherDepartments: [] };
      })
      .addCase(getEligibleEquipments.fulfilled, (state, action) => {
        state.loading = false;
        state.eligibleEquipments = action.payload.data;
      })
      .addCase(getShiftEquipmentPlans.pending, state => {
        state.shift = [];
      })
      .addCase(getShiftEquipmentPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.shift = action.payload.data;
      })
      .addCase(splitShift.pending, state => {
        state.loading = true;
      })
      .addCase(splitShift.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(splitShift.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(setCalendarPlanningMode, (state, action) => {
        state.calendarPlanningMode = action.payload;
      })
      .addCase(duplicateShift.fulfilled, (state, action) => {
        state.loading = false;
        // TODO: DIGG DEEP INTO THE OBJECT KEYS AND DO THE DUPLICATION MANUALLY HERE (I DIDN'T DO IT FOR THE TIME BEING UNTIL A NEXT ISSUE )
      })
      .addCase(duplicateShift.pending, state => {
        state.loading = true;
      })
      .addCase(duplicateShift.rejected, state => {
        state.loading = false;
      })
      .addCase(clearPlans.fulfilled, (state, action) => {
        state.loading = false;
        // TODO: DIGG DEEP INTO THE OBJECT KEYS AND DO THE DELETE MANUALLY HERE (I DIDN'T DO IT FOR THE TIME BEING UNTIL A NEXT ISSUE )
      })
      .addCase(clearPlans.pending, state => {
        state.loading = true;
      })
      .addCase(clearPlans.rejected, state => {
        state.loading = false;
      });
  },
});

const cancelOldRequest = () => {
  if (cancelToken) {
    cancelToken.cancel('Request cancelled due to new request');
  }
  cancelToken = axios.CancelToken.source();
};

export const { setActiveView, reset } = PlanningSlice.actions;

export default PlanningSlice.reducer;
