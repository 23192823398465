import { useAppSelector } from 'app/config/store';
import { CustomActivityEquipment } from 'app/shared/model/custom/custom-activity-equipment.model';
import ShiftModel from 'app/shared/model/custom/custom-shift.model';
import { IEquipment } from 'app/shared/model/equipment.model';
import { getDateTime } from 'app/shared/util/date-utils';
import React from 'react';
import { selectShifts } from '../planning.selector';

type EquipmentItemType = {
  equipments: IEquipment[];
  shiftDepartments: CustomActivityEquipment[];
  shift: ShiftModel;
};

const ShiftEquipmentItem = ({ equipments, shiftDepartments, shift }: EquipmentItemType) => {
  const shifts = useAppSelector(selectShifts) as ShiftModel[];

  const getPosition = (equipment: CustomActivityEquipment) => {
    if (!equipment) return {};

    let activityStartDate = getDateTime(equipment?.startTime.toString());
    let activityEndDate = getDateTime(equipment?.endTime.toString());

    const shiftStartDate = getDateTime(shift?.startTime);
    const shiftEndDate = getDateTime(shift?.endTime);

    let isStart = true;
    let isEnd = true;

    if (activityEndDate > shiftEndDate) {
      activityEndDate = shiftEndDate;
      isEnd = false;
    }
    if (activityStartDate < shiftStartDate) {
      activityStartDate = shiftStartDate;
      isStart = false;
    }

    const activityDurationInHours = activityEndDate - activityStartDate;
    const shiftDurationInHours = shiftEndDate - shiftStartDate;

    let startPosition = activityStartDate < shiftStartDate ? 0 : ((activityStartDate - shiftStartDate) / shiftDurationInHours) * 100;

    let durationPercentage = (activityDurationInHours / shiftDurationInHours) * 100;

    let index = equipments.indexOf(equipments.filter(eqt => eqt?.name === equipment?.equipment_id)[0]);

    return {
      left: `${startPosition}%`,
      top: `${index * 20 + 15}px`,
      width: `${durationPercentage}%`,
      borderTopLeftRadius: `${isStart ? 2 : 0}rem`,
      borderBottomLeftRadius: `${isStart ? 2 : 0}rem`,
      borderTopRightRadius: `${isEnd ? 2 : 0}rem`,
      borderBottomRightRadius: `${isEnd ? 2 : 0}rem`,
    };
  };

  const getBackPosition = index => {
    return {
      left: `0`,
      top: `${index * 20 + 15}px`,
      width: `100%`,
    };
  };

  return (
    <div className="p-1 relative" style={{ height: equipments.length * 20 + 16 }}>
      {equipments.map((equipment, index) => (
        <div key={equipment.id} className={`absolute h-2 opacity-80 bg-gray-200`} style={{ ...getBackPosition(index) }}></div>
      ))}
      {typeof shiftDepartments?.length === 'number' && shiftDepartments?.length !== 0 && (
        <>
          {shiftDepartments.map((equipment, index) => {
            const getActivity = () => {
              for (let i = 0; i < shifts?.length; i++) {
                for (let e = 0; e < shifts[i].facilities.length; e++) {
                  for (let x = 0; x < shifts[i].facilities[e].departments?.length; x++) {
                    for (let y = 0; y < shifts[i].facilities[e].departments[x]?.activities?.length; y++) {
                      if (equipment?.activity?.id === shifts[i].facilities[e].departments[x]?.activities[y]?.id) {
                        return shifts[i].facilities[e].departments[x]?.activities[y];
                      }
                    }
                  }
                }
              }
            };

            return (
              <div
                key={equipment.id}
                className={`absolute h-2 opacity-80 z-10`}
                style={{ ...getPosition(equipment), backgroundColor: getActivity()?.refActivityType?.color ?? '#676873' }}
              ></div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ShiftEquipmentItem;
