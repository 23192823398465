import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getRefLeaveTypes } from 'app/entities/ref-leave-type/ref-leave-type.reducer';
import { getEntities as getResources } from 'app/entities/resource/resource.reducer';
import { Button } from 'app/shared/components/button';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import ValidationSelect from 'app/shared/components/validation/validation-select';
import { ALL_LEAVE_STATUS_VALUES } from 'app/shared/enums/LeaveStatusEnum';
import LeaveCustom from 'app/shared/model/custom/custom-leave-model';
import { ILeave } from 'app/shared/model/leave.model';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import { getLeaveStatusI18nTranslation } from 'app/shared/util/i18n';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { createEntity, updateEntity } from './leave.reducer';

const validationSchema = Yup.object().shape({
  resource: Yup.object().required(translate('entity.validation.required')),
  refLeaveType: Yup.object().required(translate('entity.validation.required')),
  startTime: Yup.mixed().required(translate('entity.validation.required')),
  endTime: Yup.mixed().required(translate('entity.validation.required')),
  status: Yup.string()
    .oneOf(ALL_LEAVE_STATUS_VALUES, translate('entity.validation.invalid-value'))
    .required(translate('entity.validation.required')),
});

type LeaveAddEditProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  leave: LeaveCustom;
};

const LeaveAddEdit = ({ isOpen, onClose, onSave, leave }: LeaveAddEditProps) => {
  const dispatch = useAppDispatch();
  const refLeaveTypes = useAppSelector(state => state.customRefLeaveType.entities);
  const resources = useAppSelector(state =>
    state.resource.entities.slice().sort((a, b) => {
      const nameA = a?.name || '';
      const nameB = b?.name || '';
      return nameA.localeCompare(nameB);
    }),
  );

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      resource: leave?.resource,
      refLeaveType: leave?.refLeaveType,
      startTime: leave?.startTime,
      endTime: leave?.endTime,
      status: leave?.status,
    },
    validationSchema,
    onSubmit: values => submitForm(values),
  });

  useEffect(() => {
    formik.setValues({
      resource: leave?.resource,
      refLeaveType: leave?.refLeaveType,
      startTime: leave?.startTime,
      endTime: leave?.endTime,
      status: leave?.status,
    });
    getAllRefLeaveTypes();
    getAllResources();
  }, [isOpen, leave]);

  const getAllRefLeaveTypes = () => {
    dispatch(getRefLeaveTypes({}));
  };
  const getAllResources = () => {
    dispatch(getResources({}));
  };

  const submitForm = async values => {
    if (!loading) {
      const req: ILeave = {
        id: leave?.id,
        startTime: convertDateTimeToServer(values?.startTime),
        endTime: convertDateTimeToServer(values?.endTime),
        status: values?.status,
        refLeaveType: values?.refLeaveType,
        resource: values?.resource,
      };

      setLoading(true);
      try {
        if (leave?.id) {
          await dispatch(updateEntity(req));
        } else {
          await dispatch(createEntity(req));
        }
        onSave();
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      size="lg"
      title={`${translate(leave?.id ? 'rosterApp.leave.home.createOrEditLabel' : 'rosterApp.leave.home.createLabel')}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
          <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <ValidationSelect
                formik={formik}
                options={resources}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.leave.resource')}
                field="resource"
                // dropdownPosition='top'
              />
            </div>
            <div className="sm:col-span-3">
              <ValidationSelect
                formik={formik}
                options={refLeaveTypes}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.leave.refLeaveType')}
                field="refLeaveType"
                // dropdownPosition='top'
              />
            </div>
            <div className="sm:col-span-3">
              <ValidationInput formik={formik} label={translate('rosterApp.leave.startTime')} type="date" field="startTime" />
            </div>
            <div className="sm:col-span-3">
              <ValidationInput formik={formik} label={translate('rosterApp.leave.endTime')} type="date" field="endTime" />
            </div>
            <div className="sm:col-span-6">
              <ValidationSelect
                formik={formik}
                options={ALL_LEAVE_STATUS_VALUES.map(opt => ({ key: opt, value: getLeaveStatusI18nTranslation(opt) }))}
                optionsKey="key"
                optionsValue="value"
                label={translate('rosterApp.leave.status')}
                field="status"
                object={false}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 justify-end p-3 md:p-4 border-t border-gray-200 rounded-b">
          <Button variant="outline" type="button" onClick={onClose}>
            {translate('entity.action.cancel')}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
            {translate('entity.action.save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default LeaveAddEdit;
