import React, { forwardRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { selectResourceShifts } from './calendar.selector';
import { setCurrentDate, setCurrentMonth, setCurrentYear } from './calendar.reducer';
import dayjs from 'dayjs';
import { Button, ButtonProps } from 'app/shared/components/button';
import DatePicker from 'react-datepicker';

const CalendarNavigation = () => {
  const dispatch = useAppDispatch();
  const { currentDate, currentYear, currentMonth } = useAppSelector(selectResourceShifts);

  const goToNextMonth = () => {
    if (currentMonth < 12) {
      dispatch(setCurrentDate(new Date(currentYear, currentMonth, 1).toString()));
      dispatch(setCurrentMonth(currentMonth + 1));
      return;
    }
    dispatch(setCurrentDate(new Date(currentYear + 1, 0, 1).toString()));
    dispatch(setCurrentYear(currentYear + 1));
    dispatch(setCurrentMonth(1));
  };
  const goToPrevMonth = () => {
    if (currentMonth > 1) {
      dispatch(setCurrentDate(new Date(currentYear, currentMonth - 2, 1).toString()));
      dispatch(setCurrentMonth(currentMonth - 1));
      return;
    }
    dispatch(setCurrentDate(new Date(currentYear - 1, 11, 1).toString()));
    dispatch(setCurrentYear(currentYear - 1));
    dispatch(setCurrentMonth(12));
  };
  const goToCurrentMonth = () => {
    dispatch(setCurrentMonth(new Date().getMonth() + 1));
  };

  const formattedCurrentData = dayjs(`${currentYear}-${currentMonth}-01`).format('MMMM YYYY');

  const CustomTodayButton = forwardRef<HTMLButtonElement, ButtonProps>(({ value, onClick }, ref) => (
    <Button size="sm" className="mx-2" onClick={onClick} ref={ref}>
      {value}
    </Button>
  ));

  return (
    <div className="flex flex-wrap items-center max-w-[250px]">
      <div className="flex items-center">
        <Button size="sm" className="px-2" onClick={goToPrevMonth}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        {/* <Button onClick={goToCurrentMonth}>Today</Button> */}
        <DatePicker
          selected={currentDate}
          onChange={(date: Date) => {
            dispatch(setCurrentDate(date.toISOString()));
            dispatch(setCurrentMonth(date.getMonth() + 1));
          }}
          popperPlacement="bottom-start"
          calendarStartDay={1}
          customInput={<CustomTodayButton />}
          todayButton="Today"
          dateFormat="MMM yyyy"
        />
        <Button size="sm" className="px-2" onClick={goToNextMonth}>
          <FontAwesomeIcon icon={faArrowRight} />
        </Button>
      </div>
    </div>
  );
};

export default CalendarNavigation;
