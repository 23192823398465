import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IPaginationBaseState, Translate, getPaginationState, translate } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';

import { deleteEntity, getEntities } from './activity.reducer';
import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import { IActivity } from 'app/shared/model/activity.model';
import { convertDateTimeFromServer, formatDate } from 'app/shared/util/date-utils';
import { hasPermission } from 'app/shared/reducers/authentication.selector';
import ActivityAddEdit from './activity-add-edit';
import DeleteConfirmationPopup from 'app/shared/components/confirmation-popup/delete-confirmation-popup';

export const Activity = ({ showDetails = false }) => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  pageLocation.search = '';

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'startTime'), pageLocation.search),
  );

  const [selectedActivity, setSelectedActivity] = useState<IActivity>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletingEntities, setDeletingEntities] = useState<IActivity[]>();
  const [confirmationText, setConfirmationText] = useState('');
  const activityList = useAppSelector(state => state.activity.entities);
  const loading = useAppSelector(state => state.activity.loading);
  const totalItems = useAppSelector(state => state.activity.totalItems);
  const hasActivityAddPermission = useAppSelector((state: IRootState) => hasPermission(state, 'activity_add'));

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const dtOptions: DataTableOptions = {
    headers: [],
    columns: [
      {
        key: 'refActivityType',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <>
            {row.refActivityType ? (
              <Link
                className="text-blue-500 hover:text-blue-700 no-underline font-medium"
                to={`/ref-activity-type/${row.refActivityType.id}`}
              >
                {row.refActivityType?.name}
              </Link>
            ) : (
              ''
            )}
          </>
        ),
      },
      {
        key: 'startTime',
        filterable: true,
        sortable: true,
        render: row => convertDateTimeFromServer(row.startTime, 'YYYY-MM-DD'),
      },
      {
        key: 'endTime',
        filterable: true,
        sortable: true,
        render: row => convertDateTimeFromServer(row.endTime, 'YYYY-MM-DD'),
      },
      {
        key: 'refDepartment',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <>
            {row.refDepartment ? (
              <Link className="text-blue-500 hover:text-blue-700 no-underline font-medium" to={`/department`}>
                {row.refDepartment?.name}
              </Link>
            ) : (
              ''
            )}
          </>
        ),
      },
      { key: 'notes', filterable: true, sortable: true },
    ],
  };

  dtOptions.headers = dtOptions.columns.map(c => translate(`rosterApp.activity.${c.key}`));

  const handleAdd = () => {
    setSelectedActivity(null);
    setIsModalOpen(true);
  };

  const handleUpdate = (rows: IActivity[]) => {
    setSelectedActivity(rows[0]);
    setIsModalOpen(true);
  };

  const handleDelete = (rows: IActivity[]) => {
    setConfirmationText(
      rows.length === 1
        ? translate('rosterApp.activity.delete.question', { id: rows[0].id })
        : translate('rosterApp.activity.delete.multipleQuestion', { param: rows.length }),
    );
    setDeletingEntities(rows);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    setIsModalOpen(false);
    sortEntities();
  };

  const deleteItems = async () => {
    deletingEntities.forEach(async row => {
      await dispatch(deleteEntity(row.id));
    });
    setConfirmationText('');
    sortEntities();
    setSelectedActivity(null);
  };

  const getDataTable = () => (
    <>
      {activityList && (
        <DataTable
          data={activityList}
          options={dtOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          showDetails={showDetails}
          createLabel={translate('rosterApp.activity.home.createLabel')}
          notFoundLabel={translate('rosterApp.activity.home.notFound')}
          refreshListLabel={translate('rosterApp.activity.home.refreshListLabel')}
          hideCreateAction={!hasActivityAddPermission}
          title={showDetails && translate('global.menu.entities.activities')}
        />
      )}
    </>
  );

  return (
    <>
      {!showDetails && (
        <h2 id="activity-heading" className={`mb-2 whitespace-nowrap`} data-cy="ActivityHeading">
          <Translate contentKey="global.menu.entities.activities">Activities</Translate>
        </h2>
      )}

      {getDataTable()}

      <DeleteConfirmationPopup
        open={confirmationText?.length > 0}
        onSave={deleteItems}
        onCancel={() => setConfirmationText('')}
        children={confirmationText}
        saveClassName="btn-danger"
      />

      <ActivityAddEdit isOpen={isModalOpen} onClose={handleClose} onSave={handleSave} activity={selectedActivity} />
    </>
  );
};

export default Activity;
