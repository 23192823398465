import Modal from 'app/shared/components/modal/Modal';
import React from 'react';
import { translate } from 'react-jhipster';
import { Button } from 'app/shared/components/button';

type ShiftSplitModalProps = {
  isOpen: boolean;
  message: string;
  onClose: () => void;
};

const ShiftSplitModal = ({ isOpen, message, onClose }: ShiftSplitModalProps) => {
  return (
    <Modal size="lg" title={`${translate('error.modalTitle')}`} isOpen={isOpen} onClose={onClose}>
      <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
        {message}
      </div>
      {/* Buttons */}
      <div className="flex items-center justify-end p-3 gap-2 md:p-4 border-t border-gray-200 rounded-b">
        <Button variant="outline" onClick={onClose} type="button">
          {translate('entity.action.cancel')}
        </Button>
        <Button variant="destructive" onClick={onClose}>
          {translate('entity.action.ok')}
        </Button>
      </div>
    </Modal>
  );
};

export default ShiftSplitModal;
