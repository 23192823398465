import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_BASE_URL } from 'app/config/constants';
import { ResourceShiftModel } from 'app/shared/model/custom/custom-shift-resource.model';
import { IResource } from 'app/shared/model/resource.model';
import { getCalendarCellBgColorById } from 'app/shared/util/color-utils';
import axios from 'axios';

export type CalendarStateType = {
  loading: boolean;
  resourceShifts: ResourceShiftModel;
  currentDate: string;
  currentYear: number;
  currentMonth: number;
  resourceId: number | null;
  filterResources: IResource[];
};

const today = new Date();

export const initialState: CalendarStateType = {
  loading: false,
  resourceShifts: {
    shiftsWithResourcePlan: [],
    shiftsWithTeamPlan: [],
  },
  currentDate: today.toISOString(),
  currentYear: today.getFullYear(),
  currentMonth: today.getMonth() + 1,
  resourceId: null, // id of the connected resource
  filterResources: [],
};

export type GetResourceShiftsByStartDateAndEndDateParams = {
  resourceId: number;
  startDate: string;
  endDate: string;
};

export const getResourceShifts = createAsyncThunk(
  `shifts/resources`,
  async ({ resourceId, startDate, endDate }: GetResourceShiftsByStartDateAndEndDateParams) => {
    const requestUrl = `${API_BASE_URL}/shifts/resource/${resourceId}?startDate=${startDate}&endDate=${endDate}`;
    return axios.get<ResourceShiftModel>(requestUrl);
  },
);

export const findResourcesBySearchQuery = createAsyncThunk(`resource/findResourcesBySearchQuery`, async (searchQuery?: string) =>
  axios.get<IResource[]>(`${API_BASE_URL}/resource/find?searchQuery=${searchQuery}`),
);

export const CalendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setCurrentDate(state, action) {
      state.currentDate = action.payload;
    },
    setCurrentYear(state, action) {
      state.currentYear = action.payload;
    },
    setCurrentMonth(state, action) {
      state.currentMonth = action.payload;
    },
    setResourceId(state, action) {
      state.resourceId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getResourceShifts.pending, state => {
        state.loading = true;
      })
      .addCase(getResourceShifts.fulfilled, (state, action) => {
        state.loading = false;

        const newResourceShifts = [];

        for (let i = 0; i < action?.payload?.data?.shiftsWithResourcePlan?.length; i++) {
          const shift = action?.payload?.data?.shiftsWithResourcePlan[i];
          if (!shift?.bgColor) {
            shift.bgColor = getCalendarCellBgColorById(shift?.refShiftType?.id);
          }
          newResourceShifts.push(shift);
        }

        action.payload.data.shiftsWithResourcePlan = newResourceShifts;

        state.resourceShifts = action.payload.data;
      })
      .addCase(getResourceShifts.rejected, state => {
        state.loading = false;
        state.resourceShifts = {
          shiftsWithResourcePlan: [],
          shiftsWithTeamPlan: [],
        };
      })
      .addCase(findResourcesBySearchQuery.pending, state => {
        state.loading = true;
      })
      .addCase(findResourcesBySearchQuery.fulfilled, (state, action) => {
        state.loading = false;
        state.filterResources = action.payload.data;
      })
      .addCase(findResourcesBySearchQuery.rejected, state => {
        state.loading = false;
        state.filterResources = [];
      });
  },
});
export const { setCurrentDate, setCurrentMonth, setCurrentYear, setResourceId } = CalendarSlice.actions;

export default CalendarSlice.reducer;
