import React, { useState, useEffect, Fragment } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Translate, getPaginationState, translate } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';

import { deleteEntity, getEntities } from './team.reducer';
import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import { ITeam } from 'app/shared/model/team.model';
import { hasPermission } from 'app/shared/reducers/authentication.selector';
import DeleteConfirmationPopup from 'app/shared/components/confirmation-popup/delete-confirmation-popup';
import TeamAddEdit from './team-add-edit';

export const Team = ({ showDetails = false }) => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  pageLocation.search = '';

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'name'), pageLocation.search),
  );
  const teamList = useAppSelector(state => state.team.entities);
  const loading = useAppSelector(state => state.team.loading);
  const totalItems = useAppSelector(state => state.team.totalItems);
  const hasTeamAddPermission = useAppSelector((state: IRootState) => hasPermission(state, 'team_add'));
  const [selectedEntity, setSelectedEntity] = useState<ITeam>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletingEntities, setDeletingEntities] = useState<ITeam[]>();
  const [confirmationText, setConfirmationText] = useState('');

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const dtOptions: DataTableOptions = {
    headers: [],
    columns: [
      {
        key: 'name',
        filterable: true,
        sortable: true,
      },
      {
        key: 'organisation',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <Fragment>
            <Link className="text-blue-500 capitalize hover:text-blue-700 no-underline font-medium" to={`/organisation`}>
              {row.organisation?.name}
            </Link>
          </Fragment>
        ),
      },
    ],
  };
  dtOptions.headers = dtOptions.columns.map(c => translate(`rosterApp.team.${c.key}`));

  const handleAdd = () => {
    setSelectedEntity(null);
    setIsModalOpen(true);
  };

  const handleUpdate = (rows: ITeam[]) => {
    setSelectedEntity(rows[0]);
    setIsModalOpen(true);
  };

  const handleDelete = (rows: ITeam[]) => {
    setConfirmationText(
      rows.length === 1
        ? translate('rosterApp.team.delete.question', { id: rows[0].name })
        : translate('rosterApp.team.delete.multipleQuestion', { param: rows.length }),
    );
    setDeletingEntities(rows);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    setIsModalOpen(false);
    sortEntities();
  };

  const deleteItems = () => {
    deletingEntities.forEach(row => {
      dispatch(deleteEntity(row.id));
    });
    setConfirmationText('');
    sortEntities();
    setSelectedEntity(null);
  };

  const getDataTable = () => (
    <>
      {teamList && (
        <DataTable
          data={teamList}
          options={dtOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          showDetails={showDetails}
          createLabel={translate('rosterApp.team.home.createLabel')}
          notFoundLabel={translate('rosterApp.team.home.notFound')}
          refreshListLabel={translate('rosterApp.team.home.refreshListLabel')}
          hideCreateAction={!hasTeamAddPermission}
          title={showDetails && translate('global.menu.entities.teams')}
        />
      )}
    </>
  );

  return (
    <>
      {!showDetails && (
        <h2 id="team-heading" className={`mb-2 whitespace-nowrap`} data-cy="TeamHeading">
          <Translate contentKey="global.menu.entities.teams">Teams</Translate>
        </h2>
      )}

      {getDataTable()}

      <DeleteConfirmationPopup
        open={confirmationText?.length > 0}
        onSave={deleteItems}
        onCancel={() => setConfirmationText('')}
        saveClassName="btn-danger"
      >
        {confirmationText}
      </DeleteConfirmationPopup>

      <TeamAddEdit isOpen={isModalOpen} onClose={handleClose} onSave={handleSave} entity={selectedEntity} />
    </>
  );
};

export default Team;
