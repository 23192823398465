import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'app/config/store';
import { Button } from 'app/shared/components/button';
import Modal from 'app/shared/components/modal/Modal';
import ShiftModel from 'app/shared/model/custom/custom-shift.model';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { clearPlans } from '../planning.reducer';
import { validationSchema } from './ClearPlansModal';

interface ConfirmCleanPlansModalProps {
  shift: ShiftModel;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsClearPlansPopupOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  refreshShift: () => void;
  values: Yup.InferType<typeof validationSchema>;
}

const ConfirmCleanPlansModal = ({
  shift,
  values,
  setIsOpen,
  refreshShift,
  setIsClearPlansPopupOpen,
  isOpen,
}: ConfirmCleanPlansModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleConfirmClearPlans = async () => {
    setLoading(true);
    await dispatch(
      clearPlans({
        shiftId: shift.id,
        demands: values.positionDemand,
        equipment: values.equipmentPlans,
        resources: values.resourcePlans,
      }),
    );
    refreshShift();
    setLoading(false);
    setIsOpen(false);
    setIsClearPlansPopupOpen(false);
  };

  const onCancel = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      size="lg"
      title={`${translate('rosterApp.planning.confirmation-popup.confirm-clear-plans.title')}`}
      isOpen={isOpen}
      onClose={onCancel}
    >
      <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
        {translate('rosterApp.planning.confirmation-popup.confirm-clear-plans.message')}
      </div>
      {/* Buttons */}
      <div className="flex items-center justify-end p-3 gap-2 md:p-4 border-t border-gray-200 rounded-b">
        <Button variant="outline" type="button" onClick={onCancel}>
          {translate('entity.action.cancel')}
        </Button>
        <Button
          variant="destructive"
          className="flex gap-2"
          onClick={() => {
            handleConfirmClearPlans();
          }}
        >
          {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
          {translate('rosterApp.planning.confirmation-popup.clear')}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmCleanPlansModal;
