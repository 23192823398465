import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const convertDateTimeFromServer = (date: string | dayjs.Dayjs | null, dateFormat?: string): string | null => {
  if (!date) return null;
  const dateObj = typeof date === 'string' ? dayjs.utc(date) : date.utc();
  return dateObj.format(!dateFormat ? APP_LOCAL_DATETIME_FORMAT : dateFormat);
};
export const convertDateTimeToServer = (date?: string): dayjs.Dayjs | null => (date ? dayjs.utc(date) : null);

export const displayDefaultDateTime = () => {
  const currentDate = dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);
  return currentDate;
};

export function getMonthSlot(day: Date): dayjs.Dayjs[] {
  const currentDate: dayjs.Dayjs = dayjs(day);

  const daysArray: dayjs.Dayjs[] = [];
  for (let i = 0; i <= 2; i++) {
    daysArray.push(currentDate.add(i, 'day'));
  }
  return daysArray;
}

export function getNextMonthSlotFromDay(day: dayjs.Dayjs): dayjs.Dayjs[] {
  const daysArray: dayjs.Dayjs[] = [];
  for (let i = 1; i <= 3; i++) {
    daysArray.push(day.add(i, 'day'));
  }
  return daysArray;
}

export function getPrevMonthSlotFromDay(day: dayjs.Dayjs): dayjs.Dayjs[] {
  const daysArray: dayjs.Dayjs[] = [];
  for (let i = 3; i >= 1; i--) {
    daysArray.push(day.subtract(i, 'day'));
  }
  return daysArray;
}

export function isDateIncludedInSlot(date: string, dates: dayjs.Dayjs[] = []): boolean {
  const targetDay: dayjs.Dayjs = dayjs(date).startOf('day');
  return dates.some(newDate => dayjs(newDate).startOf('day').isSame(targetDay));
}

export function isShiftHappenInDay(shiftStart: string, day: dayjs.Dayjs): boolean {
  const targetDay: dayjs.Dayjs = dayjs.utc(shiftStart).startOf('day');
  return dayjs.utc(day).startOf('day').isSame(targetDay);
}

export function isMorning(dateStr: string) {
  const date: dayjs.Dayjs = dayjs(dateStr);
  return date.hour() <= 13;
}

export function isDayComplete(start: string, end: string) {
  const startDate: dayjs.Dayjs = dayjs(start);
  const endDate: dayjs.Dayjs = dayjs(end);

  const startOfDay: dayjs.Dayjs = startDate.startOf('day');
  const endOfDay: dayjs.Dayjs = startDate.endOf('day');
  return startOfDay.isSame(startDate) && endOfDay.isSame(endDate);
}

export const formatToDateOnly = (dateString: string): string => {
  const date = new Date(dateString);

  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = String(date.getUTCFullYear());

  return `${day}/${month}/${year}`;
};

export const getDateTime = (datetime: string | undefined): number => {
  return datetime === undefined ? 0 : new Date(datetime).getTime();
};

export const dateToString = (date: Date) => {
  const year: number = date.getFullYear();
  const month: string = String(date.getMonth() + 1).padStart(2, '0');
  const day: string = String(date.getDate()).padStart(2, '0');
  const hours: string = String(date.getHours()).padStart(2, '0');
  const minutes: string = String(date.getMinutes()).padStart(2, '0');
  const seconds: string = String(date.getSeconds()).padStart(2, '0');
  const milliseconds: string = String(date.getMilliseconds()).padStart(3, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
};

export const formatDate = (dateInput: string | dayjs.Dayjs) => {
  const date = typeof dateInput === 'string' ? dayjs(dateInput) : dateInput;

  const year = date?.utc()?.format('YY');
  const month = date?.utc()?.format('MM');
  const day = date?.utc()?.format('DD');
  const hour = date?.utc()?.format('HH');
  const minute = date?.utc()?.format('mm');

  return `${day}/${month}/${year}, ${hour}:${minute}`;
};

export const formatTime = (dateInput: string | dayjs.Dayjs) => {
  if (dateInput) {
    const date = typeof dateInput === 'string' ? dayjs(dateInput) : dateInput;

    const hour = date?.utc()?.format('HH');
    const minute = date?.utc()?.format('mm');

    return `${hour}:${minute}`;
  }
  return '';
};

export const isSameDay = (date1: Date, date2: Date) => {
  return dayjs(date1).isSame(dayjs(date2), 'day');
};

export const getMonthLayout = (year, month) => {
  const monthLayout: Date[][] = [];

  const firstDayOfMonth = new Date(year, month - 1, 1);
  const lastDayOfMonth = new Date(year, month, 0);

  const startDay = new Date(firstDayOfMonth);
  startDay.setDate(startDay.getDate() - ((startDay.getDay() + 6) % 7)); // Previous Monday

  const currentDay = new Date(startDay);
  while (currentDay <= lastDayOfMonth || (monthLayout.length > 0 && monthLayout[monthLayout.length - 1].length < 7)) {
    const week: Date[] = [];
    for (let i = 0; i < 7; i++) {
      week.push(new Date(currentDay));
      currentDay.setDate(currentDay.getDate() + 1);
    }
    monthLayout.push(week);
  }

  return monthLayout;
};

export const formatDateWithUTC = (date: string | Date, format: string): string => {
  if (date) {
    const utcDate = dayjs.utc(date);
    return utcDate.format(format);
  }
  return '';
};

export const getWeekIndexOfYear = (date: Date): number => {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const dayOfWeek = startOfYear.getDay() || 7; // Ensure Sunday is counted as 7th day
  const dayOfYear = Math.ceil((date.getTime() - startOfYear.getTime()) / (1000 * 60 * 60 * 24)) + 1;

  // Calculate the week number
  const weekIndex = Math.ceil((dayOfYear + dayOfWeek - 1) / 7);

  return weekIndex;
};

export const getDateFromTime = (time: string): Date => {
  if (time) {
    const [hours, minutes] = time?.split(':');
    const dateTime = new Date();
    dateTime.setHours(Number(hours), Number(minutes), 0, 0);
    return dateTime;
  }
  return null;
};
