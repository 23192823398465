import React, { useEffect, useRef } from 'react';

interface ValidationInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  formik: any;
  label: string;
  field: string;
}

const ValidationInput = ({ formik, label, field, ...props }: ValidationInputProps) => {
  const inputRef = useRef(null);
  const { type, disabled, placeholder } = props;
  // Focus the input on initial render and when field value changes
  useEffect(() => {
    if (formik.values[field] && inputRef.current) {
      inputRef.current.focus();
    }
  }, [formik.values[field]]);

  const isNotValid = () => {
    return formik.touched[field] && formik.errors[field];
  };

  const formatDateValue = (value: string) => {
    if (type === 'date' && value) {
      const date = new Date(value);
      return date.toISOString().split('T')[0];
    }
    return value;
  };

  if (type === 'checkbox') {
    return (
      <div className="flex justify-between items-center ">
        <label htmlFor={field} className={`${isNotValid() ? 'text-destrutive' : 'text-gray-900'} block text-sm font-medium `}>
          {label}
        </label>
        <input
          onChange={formik.handleChange}
          checked={formik.values[field] ? formik.values[field] : false}
          type={type}
          name={field}
          id={field}
          disabled={disabled}
          {...props}
          className={`form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out ${props.disabled && 'cursor-not-allowed'}`}
        />
      </div>
    );
  }

  return (
    <>
      <label htmlFor={field} className={`${isNotValid() ? 'text-destructive' : 'text-gray-900'} block text-sm font-medium `}>
        {label}
      </label>
      <input
        ref={inputRef}
        onChange={formik.handleChange}
        value={formatDateValue(formik.values[field] ? formik.values[field] : '')}
        type={type}
        name={field}
        id={field}
        autoComplete={field}
        disabled={disabled}
        placeholder={placeholder}
        {...props}
        className={`${isNotValid() ? 'border-destructive' : ''} mt-1 shadow-sm border-1 ${
          props.disabled && 'cursor-not-allowed'
        } border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
      />
      {isNotValid() ? <p className="m-0 text-xs text-destructive">{formik.errors[field]}</p> : null}
    </>
  );
};

export default ValidationInput;
