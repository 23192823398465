import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useState, useMemo, useEffect } from 'react';
import Avatar from '../avatar/Avatar';
import { cn } from 'app/shared/util/tailwind-merge';
import { setResourceId } from 'app/modules/calendar/calendar.reducer';
import { getImageUrl } from 'app/shared/util/file-utils';

const ResourcesSearchInput = () => {
  const resources = useAppSelector(state => state.calendar.filterResources);
  const resourceId = useAppSelector(state => state.calendar.resourceId);
  const [search, setSearch] = useState('');
  const dispatch = useAppDispatch();
  const [showResources, setShowResources] = useState(false);

  const filteredResources = useMemo(() => {
    return resources.filter(resource => {
      const fullName = `${resource.firstName} ${resource.lastName}`.toLowerCase();
      return fullName.includes(search.toLowerCase());
    });
  }, [resources, search]);

  useEffect(() => {
    if (resources?.length && resourceId) {
      let currentResource = resources.find(res => res.id === resourceId);
      setSearch(`${currentResource?.firstName} ${currentResource?.lastName}`);
    }
  }, [resourceId, resources]);

  return (
    <div className="relative flex-wrap max-w-[250px] text-white">
      <input
        onChange={e => {
          setSearch(e.target.value);
          setShowResources(true);
        }}
        onFocus={() => setShowResources(true)}
        onBlur={() => setTimeout(() => setShowResources(false), 200)}
        value={search}
        type="text"
        name="search"
        autoComplete="off"
        placeholder="Search..."
        className={cn(
          'mt-1 shadow-sm bg-gray-50 border-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2',
        )}
      />
      {showResources && (
        <div className="absolute left-0 right-0 custom-scrollbar overflow-y-auto overflow-x-hidden max-h-52 bg-white shadow-lg rounded-md whitespace-nowrap z-10">
          {filteredResources.map((resource, index) => {
            const { id, avatar, avatarContentType, firstName, lastName } = resource;
            let shortName = firstName[0]?.toUpperCase() + lastName[0]?.toUpperCase();
            return (
              <div
                key={index}
                onClick={() => {
                  setShowResources(false);
                  dispatch(setResourceId(id));
                }}
                className="hover:bg-blue-200 text-slate-800 py-1 px-1 rounded-xl hover:cursor-pointer duration-200 transition-all flex items-center z-50"
              >
                <Avatar key={`${index}_resource_avatar`} src={getImageUrl(avatarContentType, avatar)} alt={shortName} />
                <span className="ml-2 text-sm">
                  {firstName} {lastName}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ResourcesSearchInput;
