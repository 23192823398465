import React from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Link, NavLink } from 'react-router-dom';
import { type FieldError, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'app/shared/components/button';

export interface ILoginModalProps {
  loading: boolean;
  loginError: boolean;
  handleLogin: (username: string, password: string, rememberMe: boolean) => void;
  handleClose: () => void;
}

const LoginModal = (props: ILoginModalProps) => {
  const login = ({ username, password, rememberMe }) => {
    props.handleLogin(username, password, rememberMe);
  };

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });

  const { loginError, loading, handleClose } = props;

  const handleLoginSubmit = e => {
    handleSubmit(login)(e);
  };

  return (
    <section className="auth-bg bg-gray-50">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <NavLink to="/" className="flex items-center mb-2 text-2xl font-semibold text-gray-900">
          <img className="h-16" src="content/images/marsa-suite-logo.png" alt="" />
        </NavLink>
        <div className="w-full bg-white rounded-lg shadow-md md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-4 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl mb-4">{translate('login.title')}</h1>
            {loginError && (
              <div
                data-cy="loginError"
                className="flex justify-center items-center text-center text-red-500 text-sm bg-red-100 rounded p-4"
              >
                <Translate contentKey="login.messages.error.authentication">
                  <strong>Failed to sign in!</strong> Please check your credentials and try again.
                </Translate>
              </div>
            )}
            <form onSubmit={handleLoginSubmit} className="space-y-4 md:space-y-6">
              {/* <div>
                <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  <Translate contentKey="global.form.username.label">Username</Translate>
                </label>
                <input type="username" id="username" placeholder={translate('global.form.username.placeholder')} className="getFormControlError('username') ? 'border-red-500' : '' bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                <div className="text-sm text-red-500 mt-1">
                  { {{ getFormControlError('username') }} }
                </div>
              </div >
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  <Translate contentKey="login.form.password">Password</Translate>
                </label>
                <input type="password" id="password" placeholder="••••••••" required
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 getFormControlError('password') ? 'border-red-500' : ''" />
                <div className="text-sm text-red-500 mt-1">
                  { {{ getFormControlError('password') }} }
                </div>
              </div > */}
              {/* <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input id="remember" aria-describedby="remember" type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">
                      <Translate contentKey="login.form.rememberme">Remember me</Translate>
                    </label>
                  </div>
                </div>
                <NavLink to='/account/reset/request' data-cy="forgetYourPasswordSelector" className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"><Translate contentKey="login.password.forgot">Forgot password?</Translate></NavLink>
              </div> */}

              <ValidatedField
                name="username"
                label={translate('login.form.username.label')}
                placeholder={translate('global.form.username.placeholder')}
                required
                autoFocus
                data-cy="username"
                validate={{ required: 'Username cannot be empty!' }}
                register={register}
                error={errors.username as FieldError}
                isTouched={touchedFields.username}
              />
              <ValidatedField
                name="password"
                type="password"
                label={translate('login.form.password')}
                placeholder={'••••••••'}
                required
                data-cy="password"
                validate={{ required: 'Password cannot be empty!' }}
                register={register}
                error={errors.password as FieldError}
                isTouched={touchedFields.password}
              />
              <ValidatedField
                name="rememberMe"
                type="checkbox"
                check
                label={translate('login.form.rememberme')}
                value={true}
                register={register}
              />

              <Button type="submit" disabled={loading} className="w-full">
                {loading && <FontAwesomeIcon icon={faCircleNotch} spin={loading} className="mr-2 animate-spin" />}
                {!loading && <Translate contentKey="login.form.button">Sign in</Translate>}
              </Button>
              <p className="text-sm font-light text-gray-500 ">
                <Translate contentKey="login.register.noaccount">Don’t have an account yet ?</Translate>
                <Link to="/account/register" className="ml-2">
                  <Translate contentKey="login.register.label">Register a new account</Translate>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginModal;
