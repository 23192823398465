import React, { Dispatch, SetStateAction } from 'react';

import Modal from 'app/shared/components/modal/Modal';
import { translate } from 'react-jhipster';
import { updateShiftDemands } from '../planning.reducer';
import { useAppDispatch } from 'app/config/store';
import { ShiftMode } from 'app/shared/types/types';
import { Button } from 'app/shared/components/button';

interface ShiftDemandConfirmationModalProps {
  setShiftMode: Dispatch<SetStateAction<ShiftMode>>;
  setIsShiftDemandPopupOpen: Dispatch<SetStateAction<boolean>>;
  setChangedPositions: Dispatch<SetStateAction<{ payload: any; shiftDemandId: number }[]>>;
  changedPositions: { payload: any; shiftDemandId: number }[];
  refreshShift: () => void;
  isShiftDemandPopupOpen: boolean;
}

const ShiftDemandConfirmationModal = ({
  setShiftMode,
  setIsShiftDemandPopupOpen,
  setChangedPositions,
  changedPositions,
  refreshShift,
  isShiftDemandPopupOpen,
}: ShiftDemandConfirmationModalProps) => {
  const dispatch = useAppDispatch();
  const onShiftDemandeConfirmationSave = async () => {
    setShiftMode('VIEW');
    setIsShiftDemandPopupOpen(false);
    await dispatch(updateShiftDemands(changedPositions.map(pos => pos.payload)));
    setChangedPositions([]);
    refreshShift();
  };

  const onShiftDemandeConfirmationCancel = () => {
    setShiftMode('VIEW');
    setIsShiftDemandPopupOpen(false);
  };
  return (
    <Modal size="sm" title={'Confirmation'} isOpen={isShiftDemandPopupOpen} onClose={() => setIsShiftDemandPopupOpen(false)}>
      <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
        {translate('rosterApp.planning.confirmation-popup.shift-demand-confirmation-message')}
      </div>
      <div className="flex gap-2 items-center justify-end p-3 md:p-4 border-t border-gray-200 rounded-b">
        <Button variant="outline" onClick={onShiftDemandeConfirmationCancel}>
          {translate('entity.action.cancel')}
        </Button>
        {onShiftDemandeConfirmationSave && (
          <Button
            onClick={() => {
              onShiftDemandeConfirmationSave();
            }}
          >
            {translate('entity.action.save')}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ShiftDemandConfirmationModal;
