import Department from 'app/shared/model/custom/custom-department.model';
import React, { useEffect } from 'react';
import DepartmentItem from '../DepartmentItem/DepartmentItem';
import CalendarHeader from '../CalendarHeader/CalendarHeader';
import { IFacility } from 'app/shared/model/facility.model';
import { IEquipment } from 'app/shared/model/equipment.model';
import Position from 'app/shared/model/custom/custom-position.model';

type SidebarProps = {
  departments: Department[];
  facilities: IFacility[];
  equipments: IEquipment[];
  facility: IFacility;
  onUnfolded: (departmentId: number, unfolded: boolean) => void;
  currentMonthIndex: number;
  handleReset: () => void;
  handlePrev: () => void;
  handleNext: () => void;
  setFacility: (facility: IFacility) => void;
  onDateSelected: (date: Date) => void;
  selectedDate: Date;
  selectedPosition: Position;
  setSelectedPosition?: (position: Position, departmentId: number) => void;
  planEquipment?: boolean;
};

const Sidebar = ({
  departments = [],
  facilities = [],
  equipments = [],
  facility = {},
  onUnfolded,
  currentMonthIndex,
  handleReset,
  handlePrev,
  handleNext,
  onDateSelected,
  setFacility,
  selectedDate,
  selectedPosition,
  setSelectedPosition,
  planEquipment = false,
}: SidebarProps) => {
  useEffect(() => {
    setFacility(facilities.length ? facilities[0] : {});
  }, [facilities]);

  const selectFacility = e => {
    setFacility(facilities.find(fc => fc.id === Number(e.target.value)));
  };

  return (
    <aside className="w-52 h-full z-10">
      <div className="flex flex-col items-center sticky z-50 top-0 bg-white justify-center py-1 px-1.5 border border-solid border-gray-300">
        <CalendarHeader
          currentMonthIndex={currentMonthIndex}
          handleReset={handleReset}
          handlePrev={handlePrev}
          handleNext={handleNext}
          onDateSelected={onDateSelected}
          selectedDate={selectedDate}
        />
        <select
          title="Facility"
          value={facility.id}
          onChange={e => selectFacility(e)}
          className="text-sm w-full border rounded-md py-1 px-2 focus:outline-none focus:border-indigo-500 cursor-pointer"
        >
          {facilities.map(fc => (
            <option key={fc.id} value={fc.id}>
              {fc.name}
            </option>
          ))}
        </select>
      </div>
      <ul className="list-none m-0 p-0">
        {departments.map(department => (
          <DepartmentItem
            department={department}
            equipments={equipments}
            key={department.name}
            onUnfolded={onUnfolded}
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
            planEquipment={planEquipment}
          />
        ))}
      </ul>
    </aside>
  );
};

export default Sidebar;
