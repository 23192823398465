import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs';

import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import FloatingChatButton from './shared/layout/chat_bot/chatBot';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  const dispatch = useAppDispatch();
  const [children, setChildren] = useState<any>();

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    const preventContextMenu = event => {
      event.preventDefault();
    };

    // Add event listener to prevent the context menu
    document.addEventListener('contextmenu', preventContextMenu);

    // Cleanup: remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', preventContextMenu);
    };
  }, []);

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  return (
    <BrowserRouter basename={baseHref}>
      <AppRoutes setChildren={setChildren}>
        <div className="app-container overflow-hidden">
          <ToastContainer
            position={toast.POSITION.BOTTOM_LEFT}
            className="toastify-container"
            limit={1}
            autoClose={2000}
            draggable={false}
          />
          <ErrorBoundary>
            <Header
              isAuthenticated={isAuthenticated}
              isAdmin={isAdmin}
              currentLocale={currentLocale}
              ribbonEnv={ribbonEnv}
              isInProduction={isInProduction}
              isOpenAPIEnabled={isOpenAPIEnabled}
            />
          </ErrorBoundary>
          <div className="container-fluid view-container flex flex-col mt-16 h-full overflow-hidden" id="app-view-container">
            <div className="jh-card tw-card h-full overflow-auto custom-scrollbar">
              <ErrorBoundary>{children}</ErrorBoundary>
            </div>
            <FloatingChatButton />
            {/* <Footer /> */}
          </div>
        </div>
      </AppRoutes>
    </BrowserRouter>
  );
};

export default App;
