import Department from 'app/shared/model/custom/custom-department.model';
import React from 'react';
import PositionItem from '../PositionItem/PositionItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { IEquipment } from 'app/shared/model/equipment.model';
import Equipments from './equipments';
import Position from 'app/shared/model/custom/custom-position.model';

type DepartmentItemProps = {
  department: Department;
  equipments: IEquipment[];
  onUnfolded: (departmentId: number, unfolded: boolean) => void;
  selectedPosition: Position;
  setSelectedPosition?: (position: Position, departmentId: number) => void;
  planEquipment?: boolean;
};

const DepartmentItem = ({
  department,
  equipments = [],
  onUnfolded,
  selectedPosition,
  setSelectedPosition,
  planEquipment = false,
}: DepartmentItemProps) => {
  const handleUnFolded = ($event: any) => {
    onUnfolded(department.id, $event.target.checked);
  };

  const getListStyle = (unfolded: boolean): string => {
    return unfolded ? 'h-full transform transform-origin-top transition-transform ease-out transform-scale-y-100' : 'hidden';
  };

  return (
    <li>
      <input
        type="checkbox"
        id={department.name}
        defaultChecked
        onClick={handleUnFolded}
        className="absolute -left-full checked:border-b checked:border-solid checked:border-gray-300"
      />
      <label
        htmlFor={department.name}
        className={`flex items-center text-sm px-2 w-full font-bold cursor-pointer border border-solid min-h-12 hover:bg-blue-400 transition-all delay-75 ${
          department.unfolded ? 'border-b border-solid border-gray-300 bg-blue-100' : 'bg-blue-300'
        }`}
      >
        <FontAwesomeIcon className="mr-2" icon={department.unfolded ? faCaretDown : faCaretRight} />
        {department.name}
      </label>
      {department.unfolded && department.unfoldedEquipments && <Equipments equipments={equipments} />}
      <ul className={`list-none pl-0 ${getListStyle(department.unfolded)}`}>
        {[...department?.positions]
          ?.sort((a, b) => (a?.name > b?.name ? 1 : -1))
          ?.map(position => (
            <PositionItem
              onClick={() => setSelectedPosition(position, department?.id)}
              selectedPosition={selectedPosition}
              position={position}
              key={position.name}
            />
          ))}
      </ul>
    </li>
  );
};

export default DepartmentItem;
