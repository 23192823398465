import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { Translate, getPaginationState, translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import ShiftModel from 'app/shared/model/custom/custom-shift.model';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import { getEntities } from './shift.reducer';

export const CustomShift = ({ showDetails = false }) => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [selectedShift, setSelectedShift] = useState(null);
  const shiftList = useAppSelector(state => state.shift.entities);
  const loading = useAppSelector(state => state.shift.loading);
  const totalItems = useAppSelector(state => state.shift.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const dtOptions: DataTableOptions = {
    headers: ['Start time', 'End time', 'Status', 'Rotation sequence', 'Team', 'Shift type'],
    columns: [
      {
        key: 'startTime',
        filterable: true,
        sortable: true,
        render: row => convertDateTimeFromServer(row.startTime, 'YYYY-MM-DD, HH:mm'),
      },
      {
        key: 'endTime',
        filterable: true,
        sortable: true,
        render: row => convertDateTimeFromServer(row.endTime, 'YYYY-MM-DD, HH:mm'),
      },
      { key: 'status', filterable: true, sortable: true },
      { key: 'rotationSequence', filterable: true, sortable: true },
      {
        key: 'team',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <>
            {row.team ? (
              <Link className="text-blue-500 hover:text-blue-700 no-underline font-medium" to={`/team/${row?.team?.id}`}>
                {row?.team?.name}
              </Link>
            ) : (
              ''
            )}
          </>
        ),
      },
      {
        key: 'refShiftType',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <>
            {row.refShiftType ? (
              <Link className="text-blue-500 hover:text-blue-700 no-underline font-medium" to={`/ref-shift-type/${row?.refShiftType?.id}`}>
                {row?.refShiftType?.name}
              </Link>
            ) : (
              ''
            )}
          </>
        ),
      },
    ],
  };

  const handleAdd = (row: ShiftModel) => {
    setSelectedShift(row);
    navigate('/shift/new');
  };

  const handleUpdate = (rows: ShiftModel[]) => {
    setSelectedShift(rows[0]);
    navigate(`/shift/${rows[0].id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`);
  };

  const handleDelete = (row: ShiftModel) => {
    setSelectedShift(row);
    navigate(`/shift/${row.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`);
  };

  return (
    <>
      <h2 id="shift-heading" data-cy="ShiftHeading">
        <Translate contentKey="rosterApp.shift.home.title">Shifts</Translate>
      </h2>
      <DataTable
        data={shiftList}
        options={dtOptions}
        sort={sort}
        loading={loading}
        onRefresh={handleSyncList}
        onAdd={handleAdd}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        paginationState={paginationState}
        onPaginationClick={handlePagination}
        paginationTotalItems={totalItems}
        paginationMaxButtons={5}
        showDetails={showDetails}
        createLabel={translate('rosterApp.shift.home.createLabel')}
        notFoundLabel={translate('rosterApp.shift.home.notFound')}
      />
    </>
  );
};

export default CustomShift;
