import React, { useEffect, useState } from 'react';

const ValidationMultiSelect = ({ formik, options, optionsKey = null, optionsValue = null, label, field }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions(formik.values[field] || []);
  }, [formik.values[field]]);

  useEffect(() => {
    formik.setFieldValue(field, selectedOptions);
  }, [selectedOptions]);

  const isNotValid = () => {
    return formik.touched[field] && formik.errors[field];
  };

  const toggleOption = option => {
    const isSelected = selectedOptions.some(opt => (optionsKey ? opt[optionsKey] : opt) === (optionsKey ? option[optionsKey] : option));
    if (isSelected) {
      setSelectedOptions(
        selectedOptions.filter(opt => (optionsKey ? opt[optionsKey] : opt) !== (optionsKey ? option[optionsKey] : option)),
      );
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const toggleAll = () => {
    if (selectedOptions?.length === options?.length) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions([...options]);
    }
  };

  return (
    <>
      <label htmlFor={field} className={`${isNotValid() ? 'text-destructive' : 'text-gray-900'} block text-sm font-medium `}>
        {label}
      </label>
      <div className="mt-1 relative">
        <div className="custom-scrollbar bg-white shadow-md rounded-md mt-1 w-full z-10 overflow-y-auto max-h-44 border ">
          <div
            className={`cursor-pointer text-sm flex items-center py-2 px-4 ${
              options?.length === selectedOptions?.length ? 'bg-blue-100 hover:bg-blue-200' : 'hover:bg-slate-100'
            }`}
            onClick={() => toggleAll()}
          >
            <input
              checked={options?.length === selectedOptions?.length}
              readOnly
              type="checkbox"
              className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2"
            />
            Select all
          </div>
          {options?.map(option => (
            <div
              key={optionsKey ? option[optionsKey] : option}
              className={`cursor-pointer text-sm flex items-center py-2 px-4 ${
                selectedOptions?.map(o => (optionsKey ? o[optionsKey] : o)).includes(optionsKey ? option[optionsKey] : option)
                  ? 'bg-blue-100 hover:bg-blue-200'
                  : 'hover:bg-slate-100'
              }`}
              onClick={() => toggleOption(option)}
            >
              <input
                checked={selectedOptions?.map(o => (optionsKey ? o[optionsKey] : o)).includes(optionsKey ? option[optionsKey] : option)}
                type="checkbox"
                readOnly
                className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2"
              />
              {optionsKey ? option[optionsValue] : option}
            </div>
          ))}
        </div>
      </div>
      {formik.touched[field] && formik.errors[field] ? <div className="text-destructive text-xs mt-1">{formik.errors[field]}</div> : null}
    </>
  );
};

export default ValidationMultiSelect;
