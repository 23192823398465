import React, { useState, useEffect, Fragment } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Translate, getPaginationState, translate } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { deleteEntity, getEntities } from './facility.reducer';
import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import FacilityAddEdit from './facility-add-edit';
import { IFacility } from 'app/shared/model/facility.model';
import DeleteConfirmationPopup from 'app/shared/components/confirmation-popup/delete-confirmation-popup';

export const Facility = ({ showDetails = false }) => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  pageLocation.search = '';

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'name'), pageLocation.search),
  );

  const facilityList = useAppSelector(state => state.facility.entities);
  const loading = useAppSelector(state => state.facility.loading);
  const totalItems = useAppSelector(state => state.facility.totalItems);
  const [selectedEntity, setSelectedEntity] = useState<IFacility>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletingEntities, setDeletingEntities] = useState<IFacility[]>();
  const [confirmationText, setConfirmationText] = useState('');

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  let dtOptions: DataTableOptions = {
    headers: [],
    columns: [{ key: 'name', filterable: true, sortable: true }],
  };
  dtOptions.headers = dtOptions.columns.map(c => translate(`rosterApp.refDepartment.${c.key}`));

  const handleAdd = () => {
    setSelectedEntity(null);
    setIsModalOpen(true);
  };

  const handleUpdate = (rows: IFacility[]) => {
    setSelectedEntity(rows[0]);
    setIsModalOpen(true);
  };

  const handleDelete = (rows: IFacility[]) => {
    setConfirmationText(
      rows.length === 1
        ? translate('rosterApp.facility.delete.question', { id: rows[0].name })
        : translate('rosterApp.facility.delete.multipleQuestion', { param: rows.length }),
    );
    setDeletingEntities(rows);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    setIsModalOpen(false);
    sortEntities();
  };

  const deleteItems = async () => {
    deletingEntities.forEach(async row => {
      await dispatch(deleteEntity(row.id));
    });
    setConfirmationText('');
    sortEntities();
    setSelectedEntity(null);
  };

  const getDataTable = () => (
    <>
      {facilityList && (
        <DataTable
          data={facilityList}
          options={dtOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          createLabel={translate('rosterApp.facility.home.createLabel')}
          notFoundLabel={translate('rosterApp.facility.home.notFound')}
          refreshListLabel={translate('rosterApp.facility.home.refreshListLabel')}
          title={showDetails && translate('global.menu.entities.facilities')}
        />
      )}
    </>
  );

  return (
    <>
      {!showDetails && (
        <h2 id="facility-heading" className={`mb-2 whitespace-nowrap`} data-cy="FacilityHeading">
          <Translate contentKey="global.menu.entities.facilities">Facilities</Translate>
        </h2>
      )}

      {getDataTable()}

      <DeleteConfirmationPopup
        open={confirmationText?.length > 0}
        onSave={deleteItems}
        onCancel={() => setConfirmationText('')}
        children={confirmationText}
        saveClassName="btn-danger"
      />

      <FacilityAddEdit isOpen={isModalOpen} onClose={handleClose} onSave={handleSave} entity={selectedEntity} />
    </>
  );
};

export default Facility;
