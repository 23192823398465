import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'app/config/store';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { Button } from 'app/shared/components/button';
import { createEntity, updateEntity } from './ref-training.reducer';
import { IRefTraining } from 'app/shared/model/ref-training.model';

type TrainingAddEditProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  entity: IRefTraining;
};

const TrainingAddEdit = ({ isOpen, onClose, onSave, entity }: TrainingAddEditProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(translate('entity.validation.required')),
    description: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: entity?.name || '',
      description: entity?.description || '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      submitForm(values);
    },
  });

  useEffect(() => {
    formik.resetForm();
    formik.setValues({
      name: entity?.name || '',
      description: entity?.description || '',
    });
  }, [entity, isOpen]);

  const submitForm = async values => {
    if (!loading) {
      setLoading(true);
      let res = {
        id: entity?.id,
        name: values?.name,
        description: values?.description,
      };

      try {
        if (entity?.id) {
          await dispatch(updateEntity(res));
        } else {
          await dispatch(createEntity(res));
        }
        onSave();
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      size="lg"
      title={`${translate(entity?.id ? 'rosterApp.refTraining.home.createOrEditLabel' : 'rosterApp.refTraining.home.createLabel')}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
          <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6 mb-4">
            <div className="sm:col-span-6">
              <ValidationInput formik={formik} label={translate('rosterApp.refTraining.name')} field="name" />
            </div>
            <div className="sm:col-span-6">
              <ValidationInput
                formik={formik}
                label={translate('rosterApp.refTraining.description')}
                field="description"
                placeholder="..."
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end p-3 md:p-4 gap-2 border-t border-gray-200 rounded-b">
          <Button variant="outline" onClick={onClose} type="button">
            {translate('entity.action.cancel')}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
            {translate('entity.action.save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default TrainingAddEdit;
