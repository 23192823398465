import { Button } from 'app/shared/components/button';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import ValidationSelect from 'app/shared/components/validation/validation-select';
import ShiftModel from 'app/shared/model/custom/custom-shift.model';
import { useFormik } from 'formik';
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { translate } from 'react-jhipster';
import * as Yup from 'yup';

import { convertDateTimeFromServer, dateToString } from 'app/shared/util/date-utils';
import axios from 'axios';
import ConfirmDuplicationModal from './ConfirmDuplicationModal';

interface DuplicateModalProps {
  shift: ShiftModel;
  setIsDuplicatePopupOpen: Dispatch<SetStateAction<boolean>>;
  refreshShift: () => void;
  isDuplicatePopupOpen: boolean;
}

export const validationSchema = Yup.object().shape({
  day: Yup.string().required(translate('entity.validation.required')),
  shift: Yup.object().required(translate('entity.validation.required')),
  positionDemand: Yup.boolean(),
  resourcePlans: Yup.boolean(),
  equipmentPlans: Yup.boolean(),
});

const DuplicateModal = ({ shift, setIsDuplicatePopupOpen, refreshShift, isDuplicatePopupOpen }: DuplicateModalProps) => {
  const [shifts, setShifts] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const formik = useFormik<Yup.InferType<typeof validationSchema>>({
    initialValues: {
      day: convertDateTimeFromServer(shift.startTime) || '',
      shift: null,
      positionDemand: false,
      resourcePlans: false,
      equipmentPlans: false,
    },
    validationSchema,
    onSubmit: values => submitForm(),
    enableReinitialize: true,
  });

  const fetchShifts = async (signal: AbortSignal) => {
    if (isDuplicatePopupOpen) {
      const currentDate = convertDateTimeFromServer(formik.values.day);
      const startDate = new Date(currentDate);
      startDate.setHours(0, 0, 0);
      const endDate = new Date(currentDate);
      endDate.setHours(23, 59, 0);

      const requestUrl = `/api/shifts/start-time-between?startDate=${dateToString(startDate)}&endDate=${dateToString(endDate)}`;

      try {
        setIsLoading(true);
        const response = await axios.get<ShiftModel[]>(requestUrl, { signal });
        if (response) {
          setShifts(response.data);
        }
      } catch (error) {
        console.error('Failed to fetch shifts:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchShifts(controller.signal);

    return () => {
      controller.abort();
    };
  }, [isDuplicatePopupOpen, formik.values.day]);

  const submitForm = () => {
    setIsConfirmationModalOpen(true);
  };

  const onDuplicateCancel = () => {
    setIsDuplicatePopupOpen(false);
  };

  return (
    <Fragment>
      <Modal
        size="sm"
        title={`${translate('rosterApp.planning.context-menu.duplicate')} Shift Plans`}
        isOpen={isDuplicatePopupOpen}
        onClose={setIsDuplicatePopupOpen}
      >
        <form onSubmit={formik.handleSubmit}>
          <div
            className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full"
            style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}
          >
            <div
              className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full"
              style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}
            >
              <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <ValidationInput formik={formik} label={translate('rosterApp.planning.duplicate-modal.date')} type="date" field="day" />
                </div>
                <div className="sm:col-span-6">
                  <ValidationSelect
                    formik={formik}
                    loading={isLoading}
                    options={shifts}
                    optionsKey="id"
                    optionsValue="name"
                    label={translate('rosterApp.planning.duplicate-modal.shifts')}
                    field="shift"
                  />
                </div>
                <div className="sm:col-span-6">
                  <ValidationInput
                    formik={formik}
                    label={translate('rosterApp.planning.duplicate-modal.position-demands')}
                    type="checkbox"
                    field="positionDemand"
                  />
                </div>
                <div className="sm:col-span-6">
                  <ValidationInput
                    formik={formik}
                    label={translate('rosterApp.planning.duplicate-modal.resource-plans')}
                    type="checkbox"
                    field="resourcePlans"
                  />
                </div>
                <div className="sm:col-span-6">
                  <ValidationInput
                    formik={formik}
                    label={translate('rosterApp.planning.duplicate-modal.equipment-plans')}
                    type="checkbox"
                    field="equipmentPlans"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-2 items-center justify-end p-3 md:p-4 border-t border-gray-200 rounded-b">
            <Button variant="outline" type="button" onClick={onDuplicateCancel}>
              {translate('entity.action.cancel')}
            </Button>
            <Button type="submit" className={`ml-2 btn btn-primary`}>
              {translate('rosterApp.planning.confirmation-popup.duplicate')}
            </Button>
          </div>
        </form>
      </Modal>
      <ConfirmDuplicationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        setIsDuplicatePopupOpen={setIsDuplicatePopupOpen}
        shift={shift}
        refreshShift={refreshShift}
        values={formik.values}
      />
    </Fragment>
  );
};

export default DuplicateModal;
