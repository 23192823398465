import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { Fragment, useEffect, useState } from 'react';
import { Translate, getPaginationState, translate } from 'react-jhipster';
import { useLocation, useNavigate } from 'react-router-dom';

import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import RefCalendarCustom from 'app/shared/model/custom/custom-ref-calendar.model';
import { formatToDateOnly } from 'app/shared/util/date-utils';
import RefCalendarAddEdit from './ref-calendar-add-edit';
import RefCalendarDelete from './ref-calendar-delete-modal';
import { getEntities } from './ref-calendar.reducer';

export const RefCalendar = ({ showDetails = false }) => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [selectedCalendar, setSelectedCalendar] = useState<RefCalendarCustom>();
  const [deletingCalendars, setDeletingCalendars] = useState<RefCalendarCustom[]>();
  const refCalendarList = useAppSelector(state => state.refCalendar.entities);
  const loading = useAppSelector(state => state.refCalendar.loading);
  const totalItems = useAppSelector(state => state.refCalendar.totalItems);

  const calendarDtOptions: DataTableOptions = {
    headers: [],
    columns: [
      {
        key: 'day',
        filterable: true,
        sortable: true,
        render: row => formatToDateOnly(row.day),
      },
      { key: 'status', filterable: true, sortable: true },
    ],
  };
  calendarDtOptions.headers = calendarDtOptions.columns.map(c => translate(`rosterApp.refCalendar.${c.key}`));

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleAdd = () => {
    setSelectedCalendar(null);
    setIsAddEditModalOpen(true);
  };

  const handleUpdate = (rows: RefCalendarCustom[]) => {
    setSelectedCalendar(rows[0]);
    setIsAddEditModalOpen(true);
    setSelectedCalendar(null);
  };

  const handleDelete = (rows: RefCalendarCustom[]) => {
    setIsDeleteModalOpen(true);
    setDeletingCalendars(rows);
  };

  const handleSaveAddEdit = () => {
    setIsAddEditModalOpen(false);
    setSelectedCalendar(null);
  };

  const handleCloseAddEdit = () => {
    setIsAddEditModalOpen(false);
    setSelectedCalendar(null);
  };

  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false);
    setSelectedCalendar(null);
  };

  const handleCloseDelete = () => {
    setIsDeleteModalOpen(false);
    setSelectedCalendar(null);
  };

  const getDataTable = () => (
    <Fragment>
      {refCalendarList && (
        <DataTable
          data={refCalendarList}
          options={calendarDtOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          showDetails={showDetails}
          createLabel={translate('rosterApp.refCalendar.home.createLabel')}
          notFoundLabel={translate('rosterApp.refCalendar.home.notFound')}
          refreshListLabel={translate('rosterApp.refCalendar.home.refreshListLabel')}
        />
      )}
    </Fragment>
  );

  return (
    <Fragment>
      <h2 id="ref-calendar-heading" className={`mb-2 whitespace-nowrap ${showDetails ? 'text-lg' : ''}`} data-cy="RefCalendarHeading">
        <Translate contentKey="rosterApp.refCalendar.home.title">Ref Calendars</Translate>
      </h2>
      {getDataTable()}
      <RefCalendarAddEdit isOpen={isAddEditModalOpen} onClose={handleCloseAddEdit} onSave={handleSaveAddEdit} calendar={selectedCalendar} />
      <RefCalendarDelete
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDelete}
        onConfirm={handleConfirmDelete}
        deletingCalendars={deletingCalendars}
      />
    </Fragment>
  );
};

export default RefCalendar;
