import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'app/config/store';
import Modal from 'app/shared/components/modal/Modal';
import React, { useState } from 'react';
import { translate } from 'react-jhipster';
import { deleteEntity } from './equipment.reducer';
import EquipmentCustom from 'app/shared/model/custom/custom-equipment.model';
import { Button } from 'app/shared/components/button';

type EquipmentDeleteProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  deletingEquipments: EquipmentCustom[];
};

const EquipmentDelete = ({ isOpen, onClose, onConfirm, deletingEquipments }: EquipmentDeleteProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const handleConfirmDeleteClick = () => {
    handleConfirmDelete();
  };
  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      for (const equipment of deletingEquipments) {
        await dispatch(deleteEntity(equipment.id));
      }
      onConfirm();
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="lg" title={`${translate('entity.delete.title')}`} isOpen={isOpen} onClose={onClose}>
      <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
        {deletingEquipments?.length && translate('rosterApp.equipment.delete.multipleQuestion', { param: deletingEquipments.length })}
      </div>
      {/* Buttons */}
      <div className="flex items-center justify-end p-3 gap-2 md:p-4 border-t border-gray-200 rounded-b">
        <Button variant="outline" onClick={onClose} type="button">
          {translate('entity.action.cancel')}
        </Button>
        <Button variant="destructive" onClick={handleConfirmDeleteClick} disabled={loading}>
          {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
          {translate('entity.action.delete')}
        </Button>
      </div>
    </Modal>
  );
};

export default EquipmentDelete;
