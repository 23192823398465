import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Translate, getPaginationState, translate } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { deleteEntity, getEntities } from './ref-equipment-type.reducer';
import { IRefEquipmentType } from 'app/shared/model/ref-equipment-type.model';
import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import DeleteConfirmationPopup from 'app/shared/components/confirmation-popup/delete-confirmation-popup';
import EquipmentTypeAddEdit from './equipment-type-add-edit';
import Avatar from 'app/shared/components/avatar/Avatar';
import { getImageUrl } from 'app/shared/util/file-utils';

export const RefEquipmentType = ({ showDetails = false }) => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  pageLocation.search = '';

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'name'), pageLocation.search),
  );

  const refEquipmentTypeList = useAppSelector(state => state.refEquipmentType.entities);
  const loading = useAppSelector(state => state.refEquipmentType.loading);
  const totalItems = useAppSelector(state => state.refEquipmentType.totalItems);
  const [selectedEntity, setSelectedEntity] = useState<IRefEquipmentType>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletingEntities, setDeletingEntities] = useState<IRefEquipmentType[]>();
  const [confirmationText, setConfirmationText] = useState('');

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  let dtOptions: DataTableOptions = {
    headers: [],
    columns: [
      { key: 'name', filterable: true, sortable: true },
      { key: 'description', filterable: true, sortable: true },
      {
        key: 'icon',
        filterable: true,
        sortable: true,
        render: row => <>{row.icon && <Avatar size="w-8 h-8" src={getImageUrl(row.iconContentType, row.icon)} alt={``} />}</>,
      },
    ],
  };
  dtOptions.headers = dtOptions.columns.map(c => translate(`rosterApp.refEquipmentType.${c.key}`));

  const handleAdd = () => {
    setSelectedEntity(null);
    setIsModalOpen(true);
  };

  const handleUpdate = (rows: IRefEquipmentType[]) => {
    setSelectedEntity(rows[0]);
    setIsModalOpen(true);
  };

  const handleDelete = (rows: IRefEquipmentType[]) => {
    setConfirmationText(
      rows.length === 1
        ? translate('rosterApp.refEquipmentType.delete.question', { id: rows[0].name })
        : translate('rosterApp.refEquipmentType.delete.multipleQuestion', { param: rows.length }),
    );
    setDeletingEntities(rows);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    setIsModalOpen(false);
    sortEntities();
  };

  const deleteItems = async () => {
    deletingEntities.forEach(async row => {
      await dispatch(deleteEntity(row.id));
    });
    setConfirmationText('');
    sortEntities();
    setSelectedEntity(null);
  };

  const getDataTable = () => (
    <>
      {refEquipmentTypeList && (
        <DataTable
          data={refEquipmentTypeList}
          options={dtOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          createLabel={translate('rosterApp.refEquipmentType.home.createLabel')}
          notFoundLabel={translate('rosterApp.refEquipmentType.home.notFound')}
          refreshListLabel={translate('rosterApp.refEquipmentType.home.refreshListLabel')}
          title={showDetails && translate('global.menu.entities.equipmentTypes')}
        />
      )}
    </>
  );

  return (
    <>
      {!showDetails && (
        <h2 id="refEquipmentType-heading" className={`mb-2 whitespace-nowrap`} data-cy="RefEquipmentTypeHeading">
          <Translate contentKey="global.menu.entities.equipmentTypes">Equipment Types</Translate>
        </h2>
      )}

      {getDataTable()}

      <DeleteConfirmationPopup
        open={confirmationText?.length > 0}
        onSave={deleteItems}
        onCancel={() => setConfirmationText('')}
        children={confirmationText}
        saveClassName="btn-danger"
      />

      <EquipmentTypeAddEdit isOpen={isModalOpen} onClose={handleClose} onSave={handleSave} entity={selectedEntity} />
    </>
  );
};

export default RefEquipmentType;
