import React from 'react';

import CalendarHead from './CalendarHead';
import CalendarBody from './CalendarBody';

const CalendarTable = () => (
    <table className="min-w-full h-full border table-auto">
      <CalendarHead />
      <CalendarBody />
    </table>
  );

export default CalendarTable;
