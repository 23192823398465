import { useAppDispatch } from 'app/config/store';
import AutoDemandActivityModal from 'app/shared/components/auto-demand-activity-modal';
import ActivityModel from 'app/shared/model/custom/custom-activity.model';
import Department from 'app/shared/model/custom/custom-department.model';
import Position from 'app/shared/model/custom/custom-position.model';
import ResourcesPlans from 'app/shared/model/custom/custom-resources-plans.model';
import ShiftModel from 'app/shared/model/custom/custom-shift.model';
import { IEquipmentPlan } from 'app/shared/model/equipment-plan.model';
import { IEquipment } from 'app/shared/model/equipment.model';
import { IResourcePlan } from 'app/shared/model/resource-plan.model';
import { ChangedEquipmentPlanType, ShiftMode } from 'app/shared/types/types';
import { getDateTime } from 'app/shared/util/date-utils';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Activity } from '../Activity/Activity';
import ShiftSubItem from '../ShiftSubItem/ShiftSubItem';
import { getShiftEquipmentPlans, updateShiftsActivities } from '../planning.reducer';
import ShiftEquipmentItem from './shift-equipment-item';

type ShiftItemProps = {
  shift: ShiftModel;
  prevShift?: ShiftModel;
  hideContent?: boolean;
  shiftDepartment?: Department;
  department?: Department;
  eligibleEquipments?: any;
  changedPositions?: { payload: any; shiftDemandId: number }[];
  changedResources?: { plans: ResourcesPlans; status: string }[];
  changedEquipmentPlans?: ChangedEquipmentPlanType[];
  shiftMode?: ShiftMode;
  equipments: IEquipment[];
  getEligibleResourceByPosition: (position: Position) => any;
  updatePositionsShiftDemand?: ({ payload, shiftDemandId }: { payload: any; shiftDemandId: number }) => any;
  addResourcePlan?: (resourcePlan: IResourcePlan, position?: Position) => void;
  updateResourcePlan?: (resourcePlan: IResourcePlan) => void;
  addEquipmentPlan?: (equipmentPlan: IEquipmentPlan) => void;
  deleteResourcePlan?: (planId: number, resourceId: number, organigramId: number) => void;
  deleteEquipmentPlan?: (planId: number, equipmentId: number, resourceId: number, organigramId: number) => void;
  setEquipmentUnfolded: (departmentId: number, value: boolean) => void;
  planEquipment?: boolean;
  refreshShift: () => void;
};

const ShiftItem = ({
  shift,
  prevShift,
  shiftDepartment,
  hideContent = false,
  department,
  eligibleEquipments,
  updatePositionsShiftDemand,
  changedPositions,
  changedResources,
  changedEquipmentPlans,
  shiftMode = 'VIEW',
  equipments = [],
  addResourcePlan,
  updateResourcePlan,
  addEquipmentPlan,
  deleteResourcePlan,
  deleteEquipmentPlan,
  setEquipmentUnfolded,
  getEligibleResourceByPosition,
  planEquipment = false,
  refreshShift,
}: ShiftItemProps) => {
  const [shiftDetails, setShiftDetails] = useState<ShiftModel>(null);
  const [activities, setActivities] = useState<ActivityModel[]>([]);
  const [activityPanelHeight, setActivityPanelHeight] = useState<number>(0);
  const [openActivityAutoDemandPopup, setOpenActivityAutoDemandPopup] = useState(false);
  const dispatch = useAppDispatch();

  const handleShiftDemandChange = (positionId: number, organigramId: number, value: number) => {
    const position = getShiftPositionByPositionId(positionId, organigramId);
    if (position && position.shiftDemand && position.shiftDemand.id) {
      updatePositionShiftDemand(position, shift.id, department.id, value);
    }
  };

  const updatePositionShiftDemand = (position: Position, shiftId: number, departmentId: number, headCount: number) => {
    const payload = {
      id: position.shiftDemand.id,
      headCount: headCount,
      shift: {
        id: shiftId,
      },
      organigram: {
        id: position.organigram.id,
        refPosition: {
          id: position.id,
        },
        refDepartment: {
          id: departmentId,
        },
      },
    };
    updatePositionsShiftDemand({ payload: payload, shiftDemandId: position.shiftDemand.id });
  };

  const getShiftPositionByPositionId = (positionId: number, organigramId: number): Position | undefined => {
    let shiftPosition = cloneDeep(shiftDepartment?.positions ?? []).find(pos => pos.id === positionId);
    if (
      shiftPosition?.id &&
      changedPositions.filter(d => d.payload.organigram.refPosition.id === positionId && d.payload.organigram.id === organigramId).length
    ) {
      shiftPosition.shiftDemand.headCount = changedPositions.find(
        d => d.payload.organigram.refPosition.id === positionId && d.payload.organigram.id === organigramId,
      )?.payload?.headCount;
    }
    return shiftPosition;
  };

  useEffect(() => {
    if (shiftDepartment && shiftDepartment.activities)
      setActivities(() => {
        // get only activities of the current shift
        let acts = cloneDeep(
          shiftDepartment.activities.filter(activity => {
            const activityStart = new Date(activity.startTime as string);
            const activityEnd = new Date(activity.endTime as string);

            const shiftStart = new Date(shift.startTime as string);
            const shiftEnd = new Date(shift.endTime as string);

            return !(activityEnd <= shiftStart || activityStart >= shiftEnd);
          }),
        );
        let isActivitiesOld = [];

        // getting positions from old shift
        for (let x = 0; x < acts.length; x++) {
          if (prevShift) {
            let activityId = acts[x].id;
            let prevShiftActivity = prevShift?.facilities
              ?.flatMap(facility => facility.departments)
              ?.filter(dep => dep.id === shiftDepartment.id)[0]
              ?.activities.filter(act => act.id === activityId)[0];

            if (prevShiftActivity?.position) {
              acts[x] = { ...acts[x], position: prevShiftActivity.position, color: prevShiftActivity.color };
              isActivitiesOld[x] = true;
            } else if (acts[x]?.position) {
              isActivitiesOld[x] = true;
            } else {
              isActivitiesOld[x] = false;
            }
          }
        }

        // sort
        isActivitiesOld.sort((a, b) => {
          if (a) return -1;
          if (b) return 1;
          return 0;
        });

        acts = acts.sort((a, b) => {
          if (a?.position === undefined && b?.position === undefined) {
            return 0; // If both are undefined, consider them equal
          }
          if (a?.position === undefined) {
            return 1; // If only 'a' is undefined, 'b' should come first
          }
          if (b?.position === undefined) {
            return -1; // If only 'b' is undefined, 'a' should come first
          }
          // If both positions are defined, perform normal comparison
          return a.position - b.position;
        });

        // setting positions of activites
        for (let x = 0; x < acts.length; x++) {
          let position = 1;
          let positions: number[] = [];

          for (let i = 0; i < acts.length; i++) {
            if (!acts[i].color) {
              acts[i].color = acts[i].refActivityType?.color ?? '#676873';
            }

            if (isActivitiesOld[x]) {
              positions.push(acts[x]?.position || 1);
              break;
            }
            if (acts[x].id === acts[i].id) {
              acts[x] = { ...acts[x], position };
              positions.push(position);
              break;
            }
            if (
              position === acts[i]?.position &&
              getDateTime(acts[i].startTime) < getDateTime(acts[x].endTime) &&
              getDateTime(acts[i].endTime) > getDateTime(acts[x].startTime)
            ) {
              if (!positions.includes(acts[i].position || 1)) {
                positions.push(acts[i].position || 1);
                position += 1;
              }
            }
          }

          const maxHeight = Math.max(...positions) * 20;
          if (maxHeight > activityPanelHeight) {
            setActivityPanelHeight(maxHeight);
          }
        }

        // updateShiftActivities(acts, shiftDepartment.id);
        dispatch(updateShiftsActivities(acts, shiftDepartment.id));

        return acts;
      });
  }, [shiftDepartment, shiftDepartment?.activities, prevShift, shift, activityPanelHeight]);

  const getEquipmentActivies = () => {
    let activityEquipments = shiftDepartment?.activities.map(act => act.activity_equipment);
    let concatenatedEquipments = activityEquipments?.reduce((acc, curr) => acc.concat(curr), []);
    return concatenatedEquipments;
  };
  const onActivityContextMenuClick = useCallback(() => {
    if (shift?.id) {
      setOpenActivityAutoDemandPopup(true);
    }
  }, [shift]);

  useEffect(() => {
    const fetchEquipmentPlans = async () => {
      if (shiftMode !== 'PLAN_EQUIPMENT' || !shift?.id) return;

      try {
        const resultAction = (await dispatch(getShiftEquipmentPlans(shift.id))) as any;
        const equipmentPlans = resultAction.payload?.data;

        if (equipmentPlans?.length) {
          setShiftDetails(equipmentPlans[0]);
        }
      } catch (error) {
        console.error('Failed to fetch equipment plans:', error);
      }
    };
    fetchEquipmentPlans();
  }, [dispatch, shiftMode, shift?.id]);

  const getPositionEquipmentPlans = (positionId: number) => {
    let departments = shift?.facilities?.flatMap(facility => facility.departments) || [];
    let currentDepartment = departments?.find(dp => dp?.id == shiftDepartment?.id);
    return currentDepartment?.positions?.find(pos => pos?.id === positionId)?.equipmentPlans || null;
  };

  return (
    <>
      <div
        className="min-h-12 border-t border-b border-solid bg-blue-50 border-gray-300 relative w-full cursor-pointer"
        style={{ height: activityPanelHeight }}
        onClick={() => setEquipmentUnfolded(department.id, !department.unfoldedEquipments)}
        onContextMenu={() => onActivityContextMenuClick()}
      >
        {activities && activities.map(activity => <Activity key={activity.id} shift={shift} activity={activity} />)}
      </div>
      {equipments.filter(eqt => eqt.type?.toUpperCase() === 'CRANE').length !== 0 &&
        department?.unfolded &&
        department.unfoldedEquipments && (
          <ShiftEquipmentItem
            equipments={equipments.filter(eqt => eqt.type?.toUpperCase() === 'CRANE')}
            shiftDepartments={getEquipmentActivies()}
            shift={shift}
          />
        )}
      {department &&
        department?.unfolded &&
        [...department.positions]
          ?.sort((a, b) => (a?.name > b?.name ? 1 : -1))
          ?.map((position, index) => (
            <ShiftSubItem
              key={`${position.name}_${index}`}
              position={position}
              shiftId={shift.id}
              departmentId={shiftDepartment?.id}
              shiftPosition={getShiftPositionByPositionId(position.id, position.organigram.id)}
              eligibleResources={getEligibleResourceByPosition(position)}
              eligibleEquipments={eligibleEquipments}
              onShiftDemandChange={handleShiftDemandChange}
              hideContent={hideContent}
              changedResources={changedResources}
              changedEquipmentPlans={changedEquipmentPlans}
              shiftMode={shiftMode}
              equipmnetPlans={getPositionEquipmentPlans(position?.id)}
              addResourcePlan={resourcePlan => addResourcePlan(resourcePlan, position)}
              updateResourcePlan={updateResourcePlan}
              addEquipmentPlan={addEquipmentPlan}
              deleteResourcePlan={deleteResourcePlan}
              deleteEquipmentPlan={deleteEquipmentPlan}
            />
          ))}
      {openActivityAutoDemandPopup && (
        <AutoDemandActivityModal
          shiftId={shift.id}
          departmentId={department.id}
          startTime={shift.startTime}
          endTime={shift.endTime}
          isOpen={openActivityAutoDemandPopup}
          onClose={() => setOpenActivityAutoDemandPopup(false)}
          refreshShift={refreshShift}
        />
      )}
    </>
  );
};

export default ShiftItem;
