import { ModalType } from 'app/shared/types/types';
import React, { useEffect, useState } from 'react';

type ModalProps = {
  size?: ModalType;
  title: string;
  isOpen: boolean;
  onClose?: any;
  children?: React.ReactNode;
};

const Modal = ({ size = 'md', title, isOpen, onClose, children }: ModalProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(isOpen);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const closeModal = () => {
    setModalOpen(false);
    if (onClose) onClose();
  };

  const getWidth = () => {
    switch (size) {
      case 'sm':
        return 'max-w-sm';
      case 'lg':
        return 'max-w-2xl';
      default:
        'max-w-md';
    }
  };

  return (
    <div
      style={{ zIndex: 9999 }}
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ${
        modalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
    >
      <div
        className={`bg-white rounded-lg overflow-visible ${getWidth()} w-full transform transition-transform duration-300 ${
          modalOpen ? 'scale-100' : 'scale-95'
        }`}
      >
        {/* Header */}
        <div className="flex items-center justify-between p-3 md:p-3 border-b rounded-t ">
          <h3 className="text-xl font-semibold text-gray-900  mb-0">{title}</h3>
          <button
            onClick={closeModal}
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="static-modal"
          >
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        {/* Content */}
        {children}
      </div>
    </div>
  );
};

export default Modal;
