import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getPaginationState, Translate, translate } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { deleteEntity, getEntities } from './ref-department.reducer';
import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import RefDepartmentAddEdit from './ref-department-add-edit';
import CustomDepartmentPosition from 'app/shared/model/custom/custom-department-position.model';
import DeleteConfirmationPopup from 'app/shared/components/confirmation-popup/delete-confirmation-popup';
import { IRefDepartment } from 'app/shared/model/ref-department.model';

export const RefDepartment = ({ showDetails = false }) => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  pageLocation.search = '';

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'name'), pageLocation.search),
  );

  const [selectedDepartment, setSelectedDepartment] = useState<CustomDepartmentPosition>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletingEntities, setDeletingEntities] = useState<IRefDepartment[]>();
  const [confirmationText, setConfirmationText] = useState('');
  const refDepartmentList = useAppSelector(state => state.refDepartment.entities);
  const loading = useAppSelector(state => state.refDepartment.loading);
  const totalItems = useAppSelector(state => state.refDepartment.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  let dtOptions: DataTableOptions = {
    headers: [],
    columns: [
      { key: 'name', filterable: true, sortable: true },
      {
        key: 'facility',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <Fragment>
            <Link className="text-blue-500 capitalize hover:text-blue-700 no-underline font-medium" to={`/facility`}>
              {row.facility?.name}
            </Link>
          </Fragment>
        ),
      },
    ],
  };
  dtOptions.headers = dtOptions.columns.map(c => translate(`rosterApp.refDepartment.${c.key}`));

  const handleAdd = () => {
    setSelectedDepartment(null);
    setIsModalOpen(true);
  };

  const handleUpdate = (rows: CustomDepartmentPosition[]) => {
    setSelectedDepartment(rows[0]);
    setIsModalOpen(true);
  };

  const handleDelete = (rows: CustomDepartmentPosition[]) => {
    setConfirmationText(
      rows.length === 1
        ? translate('rosterApp.refDepartment.delete.question', { id: rows[0].name })
        : translate('rosterApp.refDepartment.delete.multipleQuestion', { param: rows.length }),
    );
    setDeletingEntities(rows);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    setIsModalOpen(false);
    sortEntities();
  };

  const deleteItems = async () => {
    deletingEntities.forEach(async row => {
      await dispatch(deleteEntity(row.id));
    });
    setConfirmationText('');
    sortEntities();
    setSelectedDepartment(null);
  };

  const getDataTable = () => (
    <>
      {refDepartmentList && (
        <DataTable
          data={refDepartmentList}
          options={dtOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          createLabel={translate('rosterApp.refDepartment.home.createLabel')}
          notFoundLabel={translate('rosterApp.refDepartment.home.notFound')}
          refreshListLabel={translate('rosterApp.refDepartment.home.refreshListLabel')}
          title={showDetails && translate('global.menu.entities.departments')}
        />
      )}
    </>
  );

  return (
    <>
      {!showDetails && (
        <h2 id="ref-department-heading" className={`mb-2 whitespace-nowrap`} data-cy="RefDepartmentHeading">
          <Translate contentKey="global.menu.entities.departments">Departments</Translate>
        </h2>
      )}

      {getDataTable()}

      <DeleteConfirmationPopup
        open={confirmationText?.length > 0}
        onSave={deleteItems}
        onCancel={() => setConfirmationText('')}
        children={confirmationText}
        saveClassName="btn-danger"
      />

      <RefDepartmentAddEdit isOpen={isModalOpen} onClose={handleClose} onSave={handleSave} department={selectedDepartment} />
    </>
  );
};

export default RefDepartment;
