import ResourcesSearchInput from 'app/shared/components/resource-search-input';
import React from 'react';
import CalendarNavigation from '../CalendarNavigation';

const CalendarHead = () => {
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  return (
    <thead>
      <tr>
        <th className="p-1 text-center flex flex-col items-center justify-center max-w-[250px]">
          <CalendarNavigation />
          <ResourcesSearchInput />
        </th>
        {daysOfWeek.map(day => (
          <th key={day} className="text-center border-l">
            {day}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default CalendarHead;
