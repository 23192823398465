import { isRTL } from 'app/config/translation';
import React, { useEffect, useState } from 'react';
import { Storage } from 'react-jhipster';

type SplitPaneProps = {
  children?: any;
  defaultWidth?: number;
  minWidth?: number;
  maxWidth?: number;
  onClickWidth?: number;
};

const SplitPane = ({ children, defaultWidth = 15, minWidth = 15, maxWidth = 60, onClickWidth = 0 }: SplitPaneProps) => {
  const [resizing, setResizing] = useState(false);
  const [width, setWidth] = useState(defaultWidth);

  useEffect(() => {
    setWidth(defaultWidth);
  }, [defaultWidth]);

  useEffect(() => {
    let isMoved = false;
    const handleMouseMove = event => {
      isMoved = true;
      let locale = Storage.session.get('locale');
      if (resizing) {
        let newWidth = (event.clientX / window.innerWidth) * 100;
        if (isRTL(locale)) {
          newWidth = ((window.innerWidth - event.clientX) / window.innerWidth) * 100;
        }
        setWidth(newWidth);
      }
    };

    const handleMouseUp = () => {
      if (resizing) {
        setResizing(false);
      }

      if (!isMoved && onClickWidth) {
        setWidth(width === onClickWidth ? defaultWidth : onClickWidth);
      }
    };

    if (resizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [resizing]);

  const handleMouseDown = event => {
    setResizing(true);
  };

  return (
    <div className="flex h-full">
      <div className="w-[calc(width-1rem)]" style={{ width: `${width}%`, minWidth: `${minWidth}%`, maxWidth: `${maxWidth}%` }}>
        {children[0]}
      </div>
      <div
        className="relative mx-2 w-1 border border-slate-400 active:bg-slate-400 cursor-col-resize rounded-md transition-all"
        onMouseDown={handleMouseDown}
      >
        <div
          style={{ top: 'calc(50% - 1.125rem)', left: '-0.1rem' }}
          className="absolute w-1.5 h-9 bg-slate-400 active:bg-slate-600 rounded-md transition-all"
        />
      </div>
      <div className="flex-1" style={{ minWidth: `${minWidth}%` }}>
        {children[1]}
      </div>
    </div>
  );
};

export default SplitPane;
