import React, { useEffect, useState } from 'react';

import CalendarBodyCell from './CalendarBodyCell';
import { getResourceShifts, GetResourceShiftsByStartDateAndEndDateParams, setResourceId } from '../calendar.reducer';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getMonthLayout, getWeekIndexOfYear } from 'app/shared/util/date-utils';
import { selectResourceShifts } from '../calendar.selector';
import { getCurrentUser } from 'app/shared/reducers/authentication.selector';

const CalendarBody = () => {
  const dispatch = useAppDispatch();
  const { currentYear, currentMonth, resourceId } = useAppSelector(selectResourceShifts);
  const currentUser = useAppSelector(getCurrentUser);

  const [params, setParams] = useState<GetResourceShiftsByStartDateAndEndDateParams | null>(null);

  useEffect(() => {
    const monthLayout = getMonthLayout(currentYear, currentMonth);
    const startDate = monthLayout[0][0];
    const endDate = monthLayout[monthLayout.length - 1][monthLayout[monthLayout.length - 1].length - 1];

    setParams({
      resourceId: resourceId || currentUser.id,
      startDate: new Date(`${dayjs(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`).toISOString(),
      endDate: new Date(`${dayjs(endDate).format('YYYY-MM-DD')}T23:59:59.999Z`).toISOString(),
    });
  }, [resourceId, currentUser, currentYear, currentMonth]);

  useEffect(() => {
    if (params) {
      dispatch(getResourceShifts(params)).unwrap();
    }
    if (!resourceId) {
      dispatch(setResourceId(currentUser.id));
    }
  }, [params, dispatch]);

  const monthLayout = getMonthLayout(currentYear, currentMonth);

  return (
    <tbody className="h-[calc(100vh-210px)]">
      {/* Adjust 200px as needed */}
      {monthLayout.map((week, weekIndex) => (
        <tr key={weekIndex} className="h-[calc((100vh-210px)/6)]">
          {/* Divide by number of weeks */}
          <td className="w-[179px] py-2 font-bold border text-center border-gray-200">Week {getWeekIndexOfYear(week[0])}</td>
          {week.map((date, dateIndex) => (
            <CalendarBodyCell key={dateIndex + date.toString()} date={date} />
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default CalendarBody;
