import Shift from 'app/modules/planning/Shift/Shift';
import Department from 'app/shared/model/custom/custom-department.model';
import ShiftModel from 'app/shared/model/custom/custom-shift.model';
import { IEquipment } from 'app/shared/model/equipment.model';
import { IResourcePlan } from 'app/shared/model/resource-plan.model';
import { ChangedResourcePlanType, ShiftMode } from 'app/shared/types/types';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

export type DayProps = {
  day: Dayjs;
  yesterdayShifts?: ShiftModel[];
  todayShifts?: ShiftModel[];
  departments?: Department[];
  selectedDate?: Date;
  equipments: IEquipment[];
  setEquipmentUnfolded: (departmentId: number, value: boolean) => void;
  planEquipment?: boolean;
  setPlanEquipment: (value: boolean) => void;
  setShiftMode?: (shiftId: number, mode: ShiftMode) => void;
  changedResourcePlans?: ChangedResourcePlanType[];
  setChangedResourcePlans?: (changedResourcePlans: ChangedResourcePlanType[]) => void;
  addResourcePlan?: (resourcePlan: IResourcePlan) => void;
  updateResourcePlan?: (resourcePlan: IResourcePlan) => void;
  deleteResourcePlan?: (planId: number, resourceId: number, organigramId: number, shiftId: number) => void;
};

const Day = ({
  day,
  yesterdayShifts,
  todayShifts,
  departments = [],
  equipments = [],
  setEquipmentUnfolded,
  planEquipment = false,
  setPlanEquipment,
  setShiftMode,
  changedResourcePlans,
  setChangedResourcePlans,
  addResourcePlan,
  updateResourcePlan,
  deleteResourcePlan,
}: DayProps) => {
  const getCurrentDayClass = () => {
    return day.format('DD-MM-YY') === dayjs().format('DD-MM-YY') ? 'text-white bg-blue-600 rounded-full text-sm' : 'text-base';
  };

  const sortShiftByStartDate = (shifts: ShiftModel[]): ShiftModel[] => {
    return shifts.sort((a, b) => {
      const dateA = new Date(a.startTime);
      const dateB = new Date(b.endTime);
      return dateA.getTime() - dateB.getTime();
    });
  };

  const getPrevShift = (index: number): ShiftModel => {
    let shift = undefined;

    if (index !== 0) {
      shift = todayShifts[index - 1];
    } else if (yesterdayShifts && yesterdayShifts.length) {
      shift = yesterdayShifts[yesterdayShifts.length - 1];
    }

    return shift;
  };

  return (
    <div className="flex flex-col h-full">
      <header className="flex items-center sticky top-0 bg-white z-[5] justify-center border-t border-r border-l border-solid border-gray-300 py-1.5">
        <p className="text-base m-0">{day.format('ddd').toUpperCase()}</p>
        <p className={`my-0 ml-2 h-6 w-6 flex items-center justify-center text-center ${getCurrentDayClass()}`}>{day.format('DD')}</p>
      </header>
      <div className="flex flex-row justify-center w-full">
        {todayShifts &&
          todayShifts.length > 0 &&
          sortShiftByStartDate(todayShifts).map((shift, index) => (
            <Shift
              key={`${shift.name}_${index}`}
              shift={shift}
              numberOfShifts={todayShifts.length}
              prevShift={getPrevShift(index)}
              departments={departments}
              equipments={equipments}
              setEquipmentUnfolded={setEquipmentUnfolded}
              planEquipment={planEquipment}
              setPlanEquipment={setPlanEquipment}
              setCurrentShiftMode={setShiftMode}
              changedResourcePlans={changedResourcePlans.filter(rp => rp?.plans?.shift?.id === shift?.id)}
              setChangedResourcePlans={setChangedResourcePlans}
              addResourcePlan={addResourcePlan}
              updateResourcePlan={updateResourcePlan}
              deleteResourcePlan={deleteResourcePlan}
            />
          ))}
        {(!todayShifts || todayShifts.length === 0) && (
          <Shift
            shift={{} as ShiftModel}
            departments={departments}
            hideContent
            numberOfShifts={todayShifts.length}
            equipments={equipments}
            setEquipmentUnfolded={setEquipmentUnfolded}
            setPlanEquipment={setPlanEquipment}
            setCurrentShiftMode={setShiftMode}
          />
        )}
      </div>
    </div>
  );
};

export default Day;
